import React from 'react';
import MyAccount from '../containers/MyAccount';

const ProgressLogging = () => (
  <div className="progress-logging">
    <h2>Progress Logging</h2>
    <div className="input-area">
      <MyAccount />
    </div>
  </div>
);

export default ProgressLogging;
