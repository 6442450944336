import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route
} from 'react-router-dom';
import TagManager from 'react-gtm-module';  //Google Tag Manager

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCalculator,
  faQuestion,
  faEye,
  faEdit,
  faSignInAlt,
  faSignOutAlt,
  faUser,
  faMale,
  faFemale,
  faTimes,
  faKey,
  faUserCircle,
  faStopCircle,
  faChair,
  faBicycle,
  faFire,
  faShoePrints,
  faSyncAlt,
  faBolt,
  faEnvelope,
  faCog,
  faSun,
  faStopwatch,
  faSave } from '@fortawesome/free-solid-svg-icons';
import { faClock,
  faTimesCircle,
  faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import Header from './components/atoms/Header';
import Footer from './components/atoms/Footer';
import Main from './components/blocks/Main';
import './styles/App.scss';

library.add(
  faCalculator,
  faQuestion,
  faEye,
  faEdit,
  faSignInAlt,
  faSignOutAlt,
  faUser,
  faMale,
  faFemale,
  faTimes,
  faKey,
  faUserCircle,
  faCalendarAlt,
  faClock,
  faStopCircle,
  faChair,
  faBicycle,
  faFire,
  faShoePrints,
  faSyncAlt,
  faBolt,
  faTimesCircle,
  faEnvelope,
  faCog,
  faSun,
  faStopwatch,
  faSave
);

// App Site Association
const reload = () => window.location.reload();

function App() {

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-TM3VGB2'
    });
    window.dataLayer.push({
      event: 'pageview'
    });
  });

  return (
    <div className="app">
      <Router>
        <Route path="/.well-known/apple-app-site-association" onEnter={reload} />
        <Route path="/.well-known/assetlinks.json" onEnter={reload} />
        <Route exact path={["/", "/reset-password/", "/account-confirmation/", "/login_fitbit/"]} onEnter={reload}>
          <div className="container">
            <Header />
          </div>
          <div className="container">
            <Main />
          </div>
          <Footer />
        </Route>
        <Route exact path="/calorieCalculator.php">
          <Redirect to="/" />
        </Route>
      </Router>
    </div>
  );
}

export default App;
