export const selectDialPosition = (selectedDialPosition) => ({
  type: 'SELECT_LEVEL',
  payload: {
    selectedDialPosition
  }
});

export const selectTime = (selectedTime) => ({
  type: 'SELECT_TIME',
  payload: {
    selectedTime
  }
});

export const selectDistance = (selectedDistance) => ({
  type: 'SELECT_DISTANCE',
  payload: {
    selectedDistance
  }
});

export const selectRevolutions = (selectedRevolutions = 0) => ({
  type: 'SELECT_REVOLUTIONS',
  payload: {
    selectedRevolutions
  }
});

export const selectDate = (selectedDate) => ({
  type: 'SELECT_DATE',
  payload: {
    selectedDate
  }
});

export const addErrorMessage = (calculatorErrorMessage) => ({
  type: 'ADD_ERROR_MESSAGE',
  payload: {
    calculatorErrorMessage
  }
});
