import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class CustomButton extends Component {
  buttonAction = (event) => {
    const { buttonClick } = this.props;
    event.preventDefault();

    buttonClick();
  }

  render() {
    const {
      extraClasses,
      type,
      iconNames,
      iconClasses,
      children
    } = this.props;

    return (
      <button
        type={type}
        className={extraClasses}
        onClick={this.buttonAction}
      >
        {
          iconNames.map((name) => (
            <FontAwesomeIcon
              className={iconClasses}
              icon={name}
              key={name}
            />
          ))
        }
        <span className="text">{children}</span>
      </button>
    );
  }
}

CustomButton.defaultProps = {
  extraClasses: '',
  iconClasses: '',
  iconNames: [],
  type: 'button'
};

CustomButton.propTypes = {
  type: PropTypes.string,
  extraClasses: PropTypes.string,
  iconClasses: PropTypes.string,
  iconNames: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.string
      )
    ])
  ),
  buttonClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default CustomButton;
