const initialState = {
  list: [],
  fetching: false,
  errorMessage: ''
};

const activityLogs = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ACTIVITY_LOGS':
    case 'UPDATE_ACTIVITY_LOGS':
      return {
        ...state,
        ...action.payload,
        errorMessage: ''
      };
    case 'ADD_ACTIVITY_LOGS_ERROR':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default activityLogs;
