import React from 'react';
import Calculator from '../containers/Calculator';
import Info from './Info';

const Main = () => (
  <main>
    <section className="calculator-section">
      <Calculator />
    </section>
    <section className="info-section">
      <Info />
    </section>
  </main>
);

export default Main;
