import React from 'react';
import calorieCalculatorImage from '../../assets/images/calorie_calculator.png';
import myAccountImage from '../../assets/images/my_account.png';

const HelpSection = () => (
  <div className="help-section">
    <h3>Important! Do this First</h3>
    <p>
      Go to the
      <i> Settings </i>
      tab and select your Bike Model, Sex, Height, Weight and Age.
      These settings are used in the calorie calculation.
    </p>
    <h3>How to Reset the Display</h3>
    <p>
      The calorie calculator uses the Time and Distance from the display
      to estimate calories burned. To reset the Time and Distance counters
      to zero, just press and hold the button on the display for about 5 seconds.
    </p>
    <h3>How to Use the Calorie Calculator</h3>
    <p>
      To calculate your calories burned simply copy the information from
      the bike into the calorie calculator and press the
      <i> Calculate Calories </i>
      button.
    </p>
    <img src={calorieCalculatorImage} alt="Calorie Calculator" />
    <h3>Date of Activity Dropdown</h3>
    <p>
      This was added because some people forget to save their results until the next day.
    </p>
    <br />
    <h3>Create a Free Account (Optional)</h3>
    <p>
      You can use the Calorie Calculator with our without an account.
    </p>
    <p>
      An account gives you the ability to:
    </p>
    <ul>
      <li>Save your daily exercise results.</li>
      <li>View your daily, weekly and monthly exercise results.</li>
      <li>Save your daily activity to your Fitbit account as footsteps or miles cycling.</li>
      <li>
        Preserve the values you entered in the
        <i> Settings </i>
        tab between devices.
      </li>
    </ul>
    <h3>How to Create a Calorie Calculator Account</h3>
    <p>
      Creating an account is simple. You only need to enter a username,
      password and an email address.
    </p>
    <p>
      To create an account:
    </p>
    <ol>
      <li>
        Scroll down to the
        <i> My Account </i>
        window below.
      </li>
      <li>
        Then click the
        <i> Login or Create an Account </i>
        button. This will open the
        <i> Login </i>
        window.
      </li>
      <li>
        At the bottom of the
        <i> Login </i>
        window click on the
        <i> Create Account </i>
        link. This will open the
        <i> Create New Account </i>
        window.
      </li>
      <li>
        Fill in the details and press the
        <i> Ceate Account </i>
        button.
      </li>
      <li>
        You&apos;ll be sent a verification email. Click on the link in the email to
        activate your account.
      </li>
    </ol>
    <br />
    <p>
      That&apos;s it! At this point you can login to your Calorie Calculator
      account using your email address and password.
    </p>
    <br />
    <h3>Linking to Fitbit</h3>
    <p>
      You can log your DeskCycle activity directly to your Fitbit account
      as footsteps or miles cycled. To do this you will first need to:
    </p>
    <ol>
      <li>Create a Calorie Calculator account. See the section above.</li>
      <li>
        Connect your Calorie Calculator account to your Fitbit account.
        This is quick and easy. The steps are listed below.
      </li>
    </ol>
    <h3>Connecting to Your Fitbit Account</h3>
    <ol>
      <li>
        Login to your Calorie Calculator. When logged in, you&apos;ll see
        the window below at the bottom of the Calorie Calculator.
      </li>
      <li>
        Click on the
        <i> Connect to Fitbit </i>
        button. You will be taken to the Fitbit authorization screen.
      </li>
      <li>
        Click on the
        <i> Allow </i>
        button on the Fitbit authorization screen. You will be
        returned to the Calorie Calculator.
      </li>
    </ol>
    <img src={myAccountImage} alt="My Account" />
    <br />
    <h3>Saving your Daily Activity</h3>
    <p>
      You can save your daily activity in the Results window if you are
      logged in, the buttons will show up in your account at the bottom
      of the results window as shown below.
    </p>
    <br />
  </div>
);

export default HelpSection;
