import React from 'react';
import packageJson from '../../../package.json';

const Footer = () => (
  <footer>
    <div className="container">
      Copyright &copy;2021 Marigold Creations, Inc.
      <span className="version">
        v.{packageJson.version}
      </span>
    </div>
  </footer>
);

export default Footer;
