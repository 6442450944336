import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../atoms/CustomModal';
import CustomButton from '../atoms/CustomButton';

class EnterPasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirmation: ''
    };
  }

  messageClasses() {
    const { errorMessage } = this.props;

    if (errorMessage) {
      return 'error';
    }

    return 'success';
  }

  displayMessage() {
    const {
      errorMessage,
      successMessage
    } = this.props;

    return errorMessage || successMessage;
  }

  handleChange = (input) => (event) => {
    this.setState({
      [input]: event.target.value
    });
  }

  handleEnterPasswordSubmit = () => {
    const { onSubmitEnterPasswordModal } = this.props;
    const {
      password,
      passwordConfirmation
    } = this.state;

    onSubmitEnterPasswordModal(
      password,
      passwordConfirmation
    );
  }

  render() {
    const {
      show,
      onCloseModal
    } = this.props;

    const {
      password,
      passwordConfirmation
    } = this.state;

    return (
      <div className="enter-password-modal">
        <CustomModal
          show={show}
          onClose={onCloseModal}
          name="EnterPasswordModal"
          title="Enter New Password"
          messageClasses={this.messageClasses()}
          message={this.displayMessage()}
        >
          <div
            className="enter-password-form"
          >
            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={this.handleChange('password')}
            />
            <label>Password</label>
            <input
              type="password"
              name="password-confirmation"
              placeholder="Password Confirmation"
              value={passwordConfirmation}
              onChange={this.handleChange('passwordConfirmation')}
            />
            <hr />
            <CustomButton
              extraClasses="submit large"
              type="submit"
              iconNames={['key']}
              iconClasses="mr-10"
              iconColor="white"
              buttonClick={this.handleEnterPasswordSubmit}
            >
              <span>Set Password</span>
            </CustomButton>
          </div>
        </CustomModal>
      </div>
    );
  }
}

EnterPasswordModal.defaultProps = {
  errorMessage: null,
  successMessage: null,
  show: false
};

EnterPasswordModal.propTypes = {
  onSubmitEnterPasswordModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string
};

export default EnterPasswordModal;
