import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../atoms/CustomModal';
import CustomSelect from '../atoms/CustomSelect';
import CustomButton from '../atoms/CustomButton';
import SelectedLogDetails from '../blocks/SelectedLogDetails';

class LogEditorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLog: {
        id: null,
        startedAt: null,
        seconds_pedaled: null,
        revolutions: null,
        total_calories: null
      }
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedLog } = this.props;

    if (prevProps.selectedLog && selectedLog === null) {
      // If the this.props.selectedLog becomes null, we clear the state.selectedLog
      this.setState({
        selectedLog: {
          id: null,
          startedAt: null,
          seconds_pedaled: null,
          revolutions: null,
          total_calories: null
        }
      });
    }
  }

  handleValueSelected = (value) => {
    const { activityLogs } = this.props;

    const selectedLog = activityLogs.filter(al => {
      // Ids on the list are integers and value is a string
      return al.id.toString() === value;
    })[0];

    // Activity Logs' List gets the keys in snake_case from the API
    this.setState({
      selectedLog: {
        id: selectedLog.id,
        startedAt: selectedLog.started_at,
        seconds_pedaled: selectedLog.seconds_pedaled,
        revolutions: selectedLog.revolutions,
        total_calories: selectedLog.total_calories,
      },
    });
  }

  handleLogSubmit = () => {
    const { selectedLog } = this.state;
    const { onLogSubmit } = this.props;

    if (selectedLog.id) {
      onLogSubmit(selectedLog);
    }
  }

  handleCloseButtonClick = () => {
    const { onCloseModal } = this.props;

    onCloseModal('LogEditorModal');
  }

  activityLogsOptions() {
    const { activityLogs } = this.props;

    return (activityLogs.map((al) => (
      {
        value: al.id,
        text: al.started_at
      }
    )));
  }

  render() {
    const {
      show,
      message,
      onCloseModal
    } = this.props;

    const { selectedLog } = this.state;

    return (
      <div className="log-editor-modal">
        <CustomModal
          show={show}
          onClose={onCloseModal}
          name="LogEditorModal"
          title="Log Editor"
          message={message}
        >
          <div
            className="log-editor-form"
          >
            <p>
              The log editor lets you delete activity logs that were created by mistake.
            </p>
            <h3>Select a Log</h3>
            <CustomSelect
              selectExtraClasses="multiple-select"
              size={7}
              options={this.activityLogsOptions()}
              optionsFormat="formatedDate"
              valueSelected={this.handleValueSelected}
              value={selectedLog.id || ''}
              name="logs"
            />
            {
              selectedLog.id
              && (
                <SelectedLogDetails
                  log={selectedLog}
                />
              )
            }
            <hr />
            <CustomButton
              extraClasses="large mb-5"
              iconNames={['sign-in-alt']}
              iconClasses="mr-10 icon-red"
              buttonClick={this.handleLogSubmit}
            >
              <span>Delete Selected Log</span>
            </CustomButton>
            <CustomButton
              extraClasses="large"
              iconNames={[['far', 'times-circle']]}
              iconClasses="mr-10 icon-gray"
              buttonClick={this.handleCloseButtonClick}
            >
              <span>Close</span>
            </CustomButton>
          </div>
        </CustomModal>
      </div>
    );
  }
}

LogEditorModal.defaultProps = {
  selectedLog: null,
  activityLogs: [],
  show: false,
  message: ''
};

LogEditorModal.propTypes = {
  selectedLog: PropTypes.shape({
    id: PropTypes.number,
    startedAt: PropTypes.string,
    seconds_pedaled: PropTypes.number,
    revolutions: PropTypes.number,
    total_calories: PropTypes.number
  }),
  activityLogs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      startedAt: PropTypes.string,
      seconds_pedaled: PropTypes.number,
      revolutions: PropTypes.number,
      total_calories: PropTypes.number
    })
  ),
  onLogSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  message: PropTypes.string
};

export default LogEditorModal;
