import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import * as storage from './utils/Storage';

const persistedState = storage.loadStateFromLocalStorage();

/* eslint-disable no-underscore-dangle */
const middlewareAndDevTools = persistedState && window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.REACT_APP_ENV !== 'production'
  ? compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  ) : applyMiddleware(thunk);

const store = createStore(
  rootReducer,
  persistedState,
  middlewareAndDevTools
);

store.subscribe(() => storage.saveStateToLocalStorage({
  user: store.getState().user,
  settings: store.getState().settings,
  progress: store.getState().progress
}));

export default store;
