import { RollbarErrorTracking } from '../components/errors/RollbarErrorTracking';

function setDialPositionValues() {
  // Add initial dialPositions
  return (
    [
      {
        value: 1, text: '1'
      },
      {
        value: 2, text: '2'
      },
      {
        value: 3, text: '3'
      },
      {
        value: 4, text: '4'
      },
      {
        value: 5, text: '5'
      },
      {
        value: 6, text: '6'
      },
      {
        value: 7, text: '7'
      },
      {
        value: 8, text: '8'
      }
    ]
  );
}

function setTimeValues() {
  // Add initial times
  const times = [];

  for (let i = 0; i < 1000; i += 1) {
    const minutes = i + 1;
    // The values are in seconds
    times.push({
      value: minutes * 60,
      text: `${minutes} minutes (${Math.floor(minutes / 60)} hours, ${minutes % 60})`
    });
  }

  return times;
}

function setDistanceValues() {
  // Add inital distances
  const distances = [];

  for (let i = 1; i <= 200; i += 0.1) {
    // Keep at most 1 decimal value
    const miles = Math.round(i * 10) / 10;
    const km = Math.round(miles * 1.60934 * 10) / 10;

    // Using .toString() to remove insignificant trailing zeros
    distances.push({
      value: miles,
      text: `${miles.toString()} miles (${km.toString()} km)`
    });
  }

  return distances;
}

function SetDatesValues() {
  // Add initial dates
  const dates = [];
  const dateLabels = [
    'Today',
    'Yesterday',
    '2 Days Ago',
    '3 Days Ago',
    '4 Days Ago',
    '5 Days Ago',
    '6 Days Ago',
    '7 Days Ago'
  ];
  const today = new Date();

  for (let i = 0; i < 8; i += 1) {
    const newDate = new Date();
    dates.push({
      value: `${new Date(newDate.setDate(today.getDate() - i))}`,
      text: dateLabels[i]
    });
  }

  return dates;
}

const initialState = {
  dialPositions: setDialPositionValues(),
  times: setTimeValues(),
  distances: setDistanceValues(),
  dates: SetDatesValues(),
  selectedDialPosition: 1,
  selectedTime: 1800,
  selectedDistance: 15,
  selectedRevolutions: 0,
  selectedDate: SetDatesValues()[0].value.toString(),
  calculatorErrorMessage: ''
};

const calculator = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_LEVEL':
    case 'SELECT_TIME':
    case 'SELECT_DISTANCE':
    case 'SELECT_REVOLUTIONS':
    case 'SELECT_DATE':
      return {
        ...state,
        ...action.payload,
        calculatorErrorMessage: ''
      };
    case 'ADD_ERROR_MESSAGE':
      RollbarErrorTracking.logErrorInRollbar('ADD_ERROR_MESSAGE', action.payload);

      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default calculator;
