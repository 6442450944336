import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomOutput from '../atoms/CustomOutput';

class ActivityLogOutput extends Component {
  displayValue() {
    const { value } = this.props;
    return (
      <span className="value">{value}</span>
    );
  }

  displayUnits() {
    const { units } = this.props;
    return (
      <span className="units">{units}</span>
    );
  }

  displaySecondaryValue() {
    const {
      secondaryValue,
      secondaryUnits
    } = this.props;
    return (
      <span className="value">
        (
        {secondaryValue}
        <span className="units">{secondaryUnits}</span>
        )
      </span>
    );
  }

  render() {
    const {
      iconColor,
      iconName,
      label,
      units,
      secondaryValue
    } = this.props;
    return (
      <div className="activity-log-output">
        <CustomOutput
          iconClasses={iconColor}
          iconName={iconName}
        >
          <span>
            {label}
            {this.displayValue()}
            {units && this.displayUnits()}
            {secondaryValue && this.displaySecondaryValue()}
          </span>
        </CustomOutput>
      </div>
    );
  }
}

ActivityLogOutput.defaultProps = {
  units: null,
  secondaryValue: null,
  secondaryUnits: null
};

ActivityLogOutput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  units: PropTypes.string,
  secondaryValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  secondaryUnits: PropTypes.string,
  iconName: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.string
    ),
    PropTypes.string
  ]).isRequired,
  iconColor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default ActivityLogOutput;
