import React, { Component } from 'react';
import TabCollection from '../blocks/TabCollection';
import CalculatorSection from '../containers/CalculatorSection';
import SettingsSection from '../containers/SettingsSection';
import HelpSection from '../blocks/HelpSection';

const tabsInfo = [
  {
    name: 'Calculator',
    iconNames: ['calculator'],
    iconColor: 'icon-green'
  },
  {
    name: 'Settings',
    iconNames: ['male', 'female'],
    iconColor: 'icon-navy'
  },
  {
    name: 'Help',
    iconNames: ['question'],
    iconColor: 'icon-blue'
  }
];

function renderCalculator() {
  return (
    <CalculatorSection />
  );
}

function renderSettings() {
  return (
    <SettingsSection />
  );
}

function renderHelp() {
  return (
    <HelpSection />
  );
}

class CalorieCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabSelected: 'Calculator'
    };
  }

  handleTabSelect = (tabSelected) => {
    this.setState({tabSelected});
  }

  renderInputScreen() {
    const { tabSelected } = this.state;

    switch (tabSelected) {
      case 'Calculator':
        return renderCalculator();
      case 'Settings':
        return renderSettings();
      case 'Help':
        return renderHelp();
      default:
        return renderCalculator();
    }
  }

  render() {
    const { tabSelected } = this.state;

    return (
      <div className="calorie-calculator">
        <h2>Calorie Calculator</h2>
        <TabCollection
          collection={tabsInfo}
          selected={tabSelected}
          onTabSelect={this.handleTabSelect}
        />
        <div className="input-area">
          {this.renderInputScreen()}
        </div>
      </div>
    );
  }
}

export default CalorieCalculator;
