import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActivityLogOutput from '../blocks/ActivityLogOutput';

class ActivityLog extends Component {
  handleCloseClick = (event) => {
    const { onClose } = this.props;
    event.preventDefault();

    onClose();
  }

  renderCloseButton() {
    return (
      <button
        type="button"
        onClick={this.handleCloseClick}
        className="activity-log-close"
      >
        <span className="mr-10">Close</span>
        <FontAwesomeIcon
          icon={['far', 'times-circle']}
        />
      </button>
    );
  }

  renderResults() {
    const {
      date,
      seconds_pedaled,
      display_miles_cycled,
      display_km_cycled,
      calories_sitting,
      additional_calories_pedaling,
      total_calories,
      miles_walked,
      km_walked,
      steps,
      speed_mph,
      speed_kmph,
      miles_cycled,
      km_cycled,
      pedal_speed_rpm,
      revolutions,
      pedal_strokes,
      average_power,
      average_torque,
      average_tangential_force
    } = this.props;
    let minutes_pedaled = Math.floor(seconds_pedaled / 60);

    return (
      <div className="results">
        <ActivityLogOutput
          iconColor="icon-lightgreen"
          iconName={['far', 'calendar-alt']}
          label={date}
        />
        <ActivityLogOutput
          iconColor="icon-yellow"
          iconName={['far', 'clock']}
          label="Duration"
          value={minutes_pedaled.toString()}
          units="minutes"
        />
        <ActivityLogOutput
          iconColor="gray"
          iconName="stop-circle"
          label="Display Distance"
          value={display_miles_cycled.toString()}
          units="miles"
          secondaryValue={display_km_cycled.toString()}
          secondaryUnits="km"
        />
        <hr />
        <ActivityLogOutput
          iconColor="icon-orange"
          iconName="chair"
          label="Calories Burned Sitting"
          value={calories_sitting.toString()}
        />
        <ActivityLogOutput
          iconColor="icon-orange"
          iconName="bicycle"
          label="Calories Burned Pedaling"
          value={additional_calories_pedaling.toString()}
        />
        <ActivityLogOutput
          iconColor="icon-orange"
          iconName="fire"
          label="Total Calories Burned"
          value={total_calories.toString()}
        />
        <hr />
        <ActivityLogOutput
          iconColor="icon-brown"
          iconName="shoe-prints"
          label="Distance"
          value={miles_walked}
          units="miles"
          secondaryValue={km_walked.toString()}
          secondaryUnits="km"
        />
        <ActivityLogOutput
          iconColor="icon-brown"
          iconName="shoe-prints"
          label="Steps"
          value={steps.toString()}
        />
        <hr />
        <ActivityLogOutput
          iconColor="icon-navy"
          iconName="bicycle"
          label="Road Bike Speed"
          value={speed_mph.toString()}
          units="mph"
          secondaryValue={speed_kmph.toString()}
          secondaryUnits="kph"
        />
        <ActivityLogOutput
          iconColor="icon-navy"
          iconName="bicycle"
          label="Road Bike Distance"
          value={miles_cycled.toString()}
          units="miles"
          secondaryValue={km_cycled.toString()}
          secondaryUnits="km"
        />
        <hr />
        <ActivityLogOutput
          iconColor="icon-lightgreen"
          iconName="sync-alt"
          label="Average Pedal RPM"
          value={pedal_speed_rpm.toString()}
          units="rpm"
        />
        <ActivityLogOutput
          iconColor="icon-lightgreen"
          iconName="sync-alt"
          label="Pedal Revolutions"
          value={revolutions.toString()}
        />
        <ActivityLogOutput
          iconColor="icon-lightgreen"
          iconName="sync-alt"
          label="Pedal Strokes"
          value={pedal_strokes.toString()}
        />
        <hr />
        <ActivityLogOutput
          iconColor="icon-yellow"
          iconName="bolt"
          label="Average Power"
          value={average_power.toString()}
          units="Watts"
        />
        {
          average_torque
          && (
            <ActivityLogOutput
              iconColor="icon-yellow"
              iconName="bolt"
              label="Average Torque"
              value={average_torque.toString()}
              units="in-lbs"
            />
          )
        }
        {
          average_tangential_force
          && (
            <ActivityLogOutput
              iconColor="icon-yellow"
              iconName="bolt"
              label="Average Tangential Force"
              value={average_tangential_force.toString()}
              units="lbs"
            />
          )
        }
      </div>
    );
  }

  render() {
    return (
      <div className="activity-log">
        {this.renderCloseButton()}
        <h2>Results</h2>
        <div className="input-area">
          {this.renderResults()}
        </div>
      </div>
    );
  }
}

ActivityLog.defaultProps = {
  average_torque: null,
  average_tangential_force: null
};

ActivityLog.propTypes = {
  onClose: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  seconds_pedaled: PropTypes.number.isRequired,
  display_miles_cycled: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  display_km_cycled: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  calories_sitting: PropTypes.number.isRequired,
  additional_calories_pedaling: PropTypes.number.isRequired,
  total_calories: PropTypes.number.isRequired,
  miles_walked: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  km_walked: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  steps: PropTypes.number.isRequired,
  speed_mph: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  speed_kmph: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  miles_cycled: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  km_cycled: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  pedal_speed_rpm: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  revolutions: PropTypes.number.isRequired,
  pedal_strokes: PropTypes.number.isRequired,
  average_power: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  average_torque: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  average_tangential_force: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

export default ActivityLog;
