import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as settingsActions from '../../actions/settings';
import CustomSelect from '../atoms/CustomSelect';

const SettingsSection = (props) => {
  const {
    selectedDevice,
    devices,
    selectDevice,
    selectedGender,
    genders,
    selectGender,
    selectedHeight,
    heights,
    selectHeight,
    selectedWeight,
    weights,
    selectWeight,
    selectedAge,
    ages,
    selectAge
  } = props;

  return (
    <div className="calculator-section">
      <h3>Calorie Calculator Settings</h3>
      <p className="small">
        Settings affect the accuracy of the calorie calculator results.
      </p>
      <hr />
      <CustomSelect
        extraClasses="inline"
        selectExtraClasses="single-select"
        labelText="Bike Model"
        value={selectedDevice}
        options={devices}
        valueSelected={selectDevice}
        name="bike"
      />
      <hr />
      <CustomSelect
        extraClasses="inline"
        selectExtraClasses="single-select"
        labelText="Sex"
        value={selectedGender}
        options={genders}
        valueSelected={selectGender}
        name="sex"
      />
      <CustomSelect
        extraClasses="inline"
        selectExtraClasses="single-select"
        labelText="Height"
        value={selectedHeight}
        options={heights}
        valueSelected={selectHeight}
        name="height"
      />
      <CustomSelect
        extraClasses="inline"
        selectExtraClasses="single-select"
        labelText="Weight"
        value={selectedWeight}
        options={weights}
        valueSelected={selectWeight}
        name="weight"
      />
      <CustomSelect
        extraClasses="inline"
        selectExtraClasses="single-select"
        labelText="Age"
        value={selectedAge}
        options={ages}
        valueSelected={selectAge}
        name="age"
      />
    </div>
  );
};

SettingsSection.propTypes = {
  selectedDevice: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  selectDevice: PropTypes.func.isRequired,
  selectedGender: PropTypes.string.isRequired,
  genders: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  selectGender: PropTypes.func.isRequired,
  selectedHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  heights: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired,
  selectHeight: PropTypes.func.isRequired,
  selectedWeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  weights: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired,
  selectWeight: PropTypes.func.isRequired,
  selectedAge: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  ages: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired,
  selectAge: PropTypes.func.isRequired
};

const mapStateToProps = ({ settings }) => (
  {
    devices: settings.devices,
    genders: settings.genders,
    heights: settings.heights,
    weights: settings.weights,
    ages: settings.ages,
    selectedDevice: settings.selectedDevice,
    selectedGender: settings.selectedGender,
    selectedHeight: settings.selectedHeight,
    selectedWeight: settings.selectedWeight,
    selectedAge: settings.selectedAge
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    ...bindActionCreators(settingsActions, dispatch)
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsSection);
