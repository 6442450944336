import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tab from '../atoms/Tab';

class TabCollection extends Component {
  isSelected(tabInfo) {
    const { selected } = this.props;

    return tabInfo.name === selected ? 'selected' : '';
  }

  handleTabClick = (tabClicked) => {
    const { onTabSelect } = this.props;

    onTabSelect(tabClicked);
  }

  render() {
    const { collection } = this.props;

    return (
      <div className="tabs">
        {
          collection.map((tabInfo) => (
            <Tab
              extraClasses={this.isSelected(tabInfo)}
              iconNames={tabInfo.iconNames}
              iconColor={tabInfo.iconColor}
              text={tabInfo.name}
              onClick={this.handleTabClick}
              key={tabInfo.name}
            />
          ))
        }
      </div>
    );
  }
}

TabCollection.defaultProps = {
  selected: false
};

TabCollection.propTypes = {
  selected: PropTypes.string,
  onTabSelect: PropTypes.func.isRequired,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      iconNames: PropTypes.arrayOf(
        PropTypes.string
      ),
      iconColor: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};

export default TabCollection;
