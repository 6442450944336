// Local Storage

export const setJWToken = (jwt) => {
  localStorage.setItem('jwToken', jwt);
};

export const getJWToken = () => localStorage.getItem('jwToken');

export const clearJWToken = () => {
  localStorage.removeItem('jwToken');
};

export const saveStateToLocalStorage = (state) => {
  // Keep the user session on local storage so
  // we don't lose everything when refreshing
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    console.log(e);
  }
};

export const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    // Redux expecting a JSON or undefined
    return undefined;
  }
};
