import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../atoms/CustomModal';
import CustomButton from '../atoms/CustomButton';

class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      requested: false
    };
  }

  handleChange = (input) => (event) => {
    this.setState({
      [input]: event.target.value,
    });
  }

  handleForgotPasswordSubmit = () => {
    const { onSubmitForgotPasswordModal } = this.props;
    const { email } = this.state;

    onSubmitForgotPasswordModal(email);
    this.setState({ requested: true });
  }

  displayRequestMessage() {
    const { requested } = this.state;

    return (
      requested
        ? 'If your account exists, you will receive an email to reset your password.'
        : ''
    );
  }

  renderSubmitButton() {
    return (
      <CustomButton
        extraClasses="submit large"
        type="submit"
        iconNames={['envelope', 'key']}
        iconClasses="mr-10"
        iconColor="white"
        buttonClick={this.handleForgotPasswordSubmit}
      >
        <span>Submit</span>
      </CustomButton>
    );
  }

  render() {
    const {
      show,
      onCloseModal
    } = this.props;

    const {
      email,
      requested
    } = this.state;

    return (
      <div className="forgot-password-modal">
        <CustomModal
          show={show}
          onClose={onCloseModal}
          name="ForgotPasswordModal"
          title="Forgot Password"
          message={this.displayRequestMessage()}
        >
          <div
            className="forgot-password-form"
          >
            <label>
              Email Address
            </label>
            <input
              type="text"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={this.handleChange('email')}
            />
            <hr />
            {
              !requested
              && this.renderSubmitButton()
            }
          </div>
        </CustomModal>
      </div>
    );
  }
}

ForgotPasswordModal.defaultProps = {
  show: false
};

ForgotPasswordModal.propTypes = {
  onSubmitForgotPasswordModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired
};

export default ForgotPasswordModal;
