import { getRequest, postRequest } from '../utils/Requests';
import { logoutUser, updateUserFitbitConnected } from './user';
import { requestMyProgress } from './progress';
import { requestMessage } from './messaging';
import { RollbarErrorTracking } from '../components/errors/RollbarErrorTracking';

export const showActivityLog = (
  user_id, date, started_at, seconds_pedaled, dial_position, display_miles_cycled, display_km_cycled,
  calories_sitting, additional_calories_pedaling, total_calories, total_calories_per_hour,
  miles_walked, km_walked, steps, speed_mph, speed_kmph, miles_cycled, km_cycled, pedal_speed_rpm,
  revolutions, pedal_strokes, average_power, average_torque, average_tangential_force,
  source_version, timezone
) => ({
  type: 'SHOW_ACTIVITY_LOG',
  payload: {
    user_id,
    date,
    started_at,
    seconds_pedaled,
    dial_position,
    display_miles_cycled,
    display_km_cycled,
    calories_sitting,
    additional_calories_pedaling,
    total_calories,
    total_calories_per_hour,
    miles_walked,
    km_walked,
    steps,
    speed_mph,
    speed_kmph,
    miles_cycled,
    km_cycled,
    pedal_speed_rpm,
    revolutions,
    pedal_strokes,
    average_power,
    average_torque,
    average_tangential_force,
    source_version,
    timezone
  }
});

export const hideActivityLog = (message = null) => ({
  type: 'HIDE_ACTIVITY_LOG',
  payload: {
    activityLogSuccessMessage: message
  }
});

export const addActivityLogError = (message) => ({
  type: 'ADD_ACTIVITY_LOG_ERROR',
  payload: {
    activityLogErrorMessage: message
  }
});

export const requestActivityLog = (
  user_id, device, gender, age, revolutions, height_inches, weight_lbs,
  dial_position, seconds_pedaled, pedal_distance, date, source_version
) => {
  // Update the time of day
  const currentTime = new Date();
  const dateWithTime = new Date(date);
  dateWithTime.setHours(currentTime.getHours());
  dateWithTime.setMinutes(currentTime.getMinutes());

  return (dispatch) => getRequest('activity_logs/new', {
    device,
    gender,
    age,
    revolutions,
    height_inches,
    weight_lbs,
    dial_position,
    seconds_pedaled,
    pedal_distance,
    started_at: `${dateWithTime.toDateString()} ${dateWithTime.toTimeString()}`,
    user_id,
    source: 'web',
    source_version
  }).then((response) => {
    const {
      user_id,
      date,
      started_at,
      seconds_pedaled,
      dial_position,
      display_miles_cycled,
      display_km_cycled,
      calories_sitting,
      additional_calories_pedaling,
      total_calories,
      total_calories_per_hour,
      miles_walked,
      km_walked,
      steps,
      speed_mph,
      speed_kmph,
      miles_cycled,
      km_cycled,
      pedal_speed_rpm,
      revolutions,
      pedal_strokes,
      average_power,
      average_torque,
      average_tangential_force,
      source_version,
      fitbit_connected,
      timezone
    } = response.data;

    // If the user is logged in and connected to fitbit, show options
    if (user_id !== undefined) {
      dispatch(updateUserFitbitConnected(fitbit_connected));
    }
    dispatch(showActivityLog(
      user_id, date, started_at, seconds_pedaled, dial_position, display_miles_cycled,
      display_km_cycled, calories_sitting, additional_calories_pedaling, total_calories,
      total_calories_per_hour, miles_walked, km_walked, steps, speed_mph, speed_kmph,
      miles_cycled, km_cycled, pedal_speed_rpm, revolutions, pedal_strokes, average_power,
      average_torque, average_tangential_force, source_version, timezone
    ));
  }).catch((error) => {
    if (typeof error.response !== 'undefined') {
      dispatch(addActivityLogError(error.response.data.error));
    } else {
      RollbarErrorTracking.logErrorInRollbar('requestActivityLog::activity_logs/new', error.response);
    }
  });
};

export const requestSaveActivityLog = () => (dispatch, getState) => {
  const { activityLog, settings, user } = getState();

  return postRequest('activity_logs', {
    activity_log: {
      device: settings.selectedDevice,
      current_weight_lbs: user.settings.weight,
      current_age: user.settings.age,
      seconds_pedaled: activityLog.seconds_pedaled,
      dial_position: activityLog.dial_position,
      calories_sitting: activityLog.calories_sitting,
      additional_calories_pedaling: activityLog.additional_calories_pedaling,
      total_calories: activityLog.total_calories,
      total_calories_per_hour: activityLog.total_calories_per_hour,
      steps: activityLog.steps,
      speed_mph: activityLog.speed_mph,
      miles_cycled: activityLog.miles_cycled,
      pedal_speed_rpm: activityLog.pedal_speed_rpm,
      revolutions: activityLog.revolutions,
      pedal_strokes: activityLog.pedal_strokes,
      average_power: activityLog.average_power,
      miles_walked: activityLog.miles_walked,
      started_at: activityLog.started_at,
      fitbit_options: user.fitbitValue,
      source: activityLog.source,
      source_version: activityLog.source_version,
      timezone: activityLog.timezone,
      user_id: user.id
    }
  }).then(() => {
    dispatch(hideActivityLog('Your results were saved to your account.'));
    dispatch(requestMyProgress());
    dispatch(requestMessage(user.id, 'myProgress'));
  }).catch((error) => {
    if (typeof error.response !== 'undefined') {
      if (error.response.status === 401) {
        dispatch(logoutUser());
      } else {
        dispatch(addActivityLogError(error.response.data.error));
      }
    } else {
      RollbarErrorTracking.logErrorInRollbar('requestSaveActivityLog::activity_logs', error.response);
    }
  });
};
