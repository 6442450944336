import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../atoms/CustomModal';
import CustomButton from '../atoms/CustomButton';

class ConfirmationModal extends Component {
  messageClasses() {
    const { errorMessage } = this.props;

    if (errorMessage) {
      return 'error';
    }

    return 'success';
  }

  displayMessage() {
    const {
      errorMessage,
      successMessage
    } = this.props;

    return errorMessage || successMessage;
  }

  render() {
    const {
      show,
      onCloseModal
    } = this.props;

    return (
      <CustomModal
        show={show}
        onClose={onCloseModal}
        name="ConfirmationModal"
        title="Email Confirmation"
        messageClasses={this.messageClasses()}
        message={this.displayMessage()}
      >
        <CustomButton
          extraClasses="large"
          iconNames={[['far', 'times-circle']]}
          iconClasses="mr-10 icon-gray"
          buttonClick={onCloseModal}
        >
          <span>Close</span>
        </CustomButton>
      </CustomModal>
    );
  }
}

ConfirmationModal.defaultProps = {
  show: false,
  errorMessage: null,
  successMessage: null
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string
};

export default ConfirmationModal;
