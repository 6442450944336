import React from 'react';
import PropTypes from 'prop-types';

const AlertMessage = (props) => {
  const {
    extraClasses,
    children
  } = props;

  return (
    <div className={`message ${extraClasses}`}>
      {children}
    </div>
  );
};

AlertMessage.defaultProps = {
  extraClasses: ''
};

AlertMessage.propTypes = {
  extraClasses: PropTypes.string,
  children: PropTypes.element.isRequired
};

export default AlertMessage;
