import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Tab extends Component {
  buttonAction = (event) => {
    const {
      onClick,
      text
    } = this.props;
    event.preventDefault();

    onClick(text);
  }

  render() {
    const {
      extraClasses,
      iconNames,
      iconColor,
      text
    } = this.props;

    return (
      <button
        type="button"
        className={`tab ${extraClasses}`}
        onClick={this.buttonAction}
      >
        {
          iconNames
          && iconNames.map((name) => (
            <FontAwesomeIcon
              className={iconColor}
              icon={name}
              key={name}
            />
          ))
        }
        <span className="text">{text}</span>
      </button>
    );
  }
}

Tab.defaultProps = {
  extraClasses: '',
  text: '',
  iconColor: ''
};

Tab.propTypes = {
  extraClasses: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  iconNames: PropTypes.arrayOf(
    PropTypes.string
  ),
  iconColor: PropTypes.string
};


export default Tab;
