import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CustomButton from '../atoms/CustomButton';
import CustomSelect from '../atoms/CustomSelect';
import AlertMessage from '../atoms/AlertMessage';
import * as activityLogActions from '../../actions/activity_log';
import * as calculatorActions from '../../actions/calculator';

class CalculatorSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rpmErrorMessage: ''
    };
  }

  validatesInput() {
    const {
      selectedDistance,
      selectedTime,
      selectedRevolutions,
      selectedDevice
    } = this.props;
    let rpm = 0;
    let distanceType = 'Distance';
    let minutes = selectedTime / 60;

    // Using revolutions to calculate speed
    if (selectedDevice !== 'deskcycle ellipse') {
      rpm = (240 * selectedDistance) / minutes;
    } else {
      rpm = selectedRevolutions / minutes;
      distanceType = 'Revolutions';
    }

    if (rpm < 10) {
      this.setState({
        rpmErrorMessage: `The calculated pedal speed of ${rpm.toFixed(1)} RPM is too slow to get an accurate Calorie estimate. Double-check the Time and ${distanceType} values from your display and try again.`
      });

      return false;
    }

    if (rpm > 160) {
      this.setState({
        rpmErrorMessage: `The calculated pedal speed of ${rpm.toFixed(1)} RPM is too fast to get an accurate Calorie estimate. Double-check the Time and ${distanceType} values from your display and try again.`
      });

      return false;
    }

    return true;
  }

  handleChange = (input) => (event) => {
    const { selectRevolutions } = this.props;

    selectRevolutions(event.target.value);
  }

  handleButtonClick = () => {
    const {
      requestActivityLog,
      user_id,
      selectedDevice,
      selectedGender,
      selectedAge,
      selectedRevolutions,
      selectedHeight,
      selectedWeight,
      selectedDialPosition,
      selectedTime,
      selectedDistance,
      selectedDate
    } = this.props;

    if (this.validatesInput()) {
      requestActivityLog(
        user_id,
        selectedDevice,
        selectedGender,
        selectedAge,
        selectedRevolutions,
        selectedHeight,
        selectedWeight,
        selectedDialPosition,
        selectedTime,
        selectedDistance,
        selectedDate,
        window.navigator.appVersion
      );

      // Clean the error message
      this.setState({
        rpmErrorMessage: ''
      });
    }
  }

  displayDistanceOrRevolutions() {
    const {
      selectedDevice,
      selectedRevolutions,
      selectedDistance,
      distances,
      selectRevolutions,
      selectDistance
    } = this.props;

    if (selectedDevice === 'deskcycle ellipse') {
      return (
        <div className="custom-input">
          <div className="label-section">
            <label>
              Revolutions
            </label>
            <div className="label-info">
              (From Display)
            </div>
          </div>
          <input
            type="number"
            name="revolutions"
            value={selectedRevolutions}
            onChange={this.handleChange('revolutions')}
          />
        </div>
      );
    }

    // Reset revolutions value if we are not using the DeskCycle Ellipse
    selectRevolutions(0);
    return (
      <CustomSelect
        extraClasses="large"
        selectExtraClasses="single-select"
        labelText="Distance"
        labelInfo="(From Display)"
        value={selectedDistance}
        options={distances}
        valueSelected={selectDistance}
        name="distance"
      />
    );
  }

  renderErrorMessage() {
    const {
      errorMessage,
      activityLogErrorMessage
    } = this.props;
    const { rpmErrorMessage } = this.state;

    return (
      <AlertMessage
        extraClasses="error"
      >
        {errorMessage || activityLogErrorMessage || rpmErrorMessage}
      </AlertMessage>
    );
  }

  render() {
    const {
      selectedDialPosition,
      dialPositions,
      selectDialPosition,
      selectedTime,
      times,
      selectTime,
      selectedDate,
      dates,
      selectDate,
      errorMessage,
      activityLogErrorMessage
    } = this.props;
    const { rpmErrorMessage } = this.state;

    return (
      <div className="calculator-section">
        <CustomSelect
          selectExtraClasses="single-select"
          labelText="Level"
          labelInfo="(From Resistance Dial)"
          value={selectedDialPosition}
          options={dialPositions}
          valueSelected={selectDialPosition}
          name="dialPosition"
        />
        <CustomSelect
          extraClasses="large"
          selectExtraClasses="single-select"
          labelText="Time"
          labelInfo="(From Display)"
          value={selectedTime}
          options={times}
          valueSelected={selectTime}
          name="time"
        />
        {this.displayDistanceOrRevolutions()}
        <CustomSelect
          extraClasses="large"
          selectExtraClasses="single-select"
          labelText="Date of Activity"
          value={selectedDate}
          options={dates}
          valueSelected={selectDate}
          name="date"
        />
        <CustomButton
          extraClasses="large"
          buttonClick={this.handleButtonClick}
          iconClasses="icon-green mr-10"
          iconNames={['calculator']}
        >
          <span>Calculate Calories</span>
        </CustomButton>
        {
          (errorMessage || activityLogErrorMessage || rpmErrorMessage)
          && this.renderErrorMessage()
        }
      </div>
    );
  }
}

CalculatorSection.defaultProps = {
  errorMessage: null,
  activityLogErrorMessage: null
};

CalculatorSection.propTypes = {
  selectedDistance: PropTypes.PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  selectedTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  selectedDevice: PropTypes.string.isRequired,
  selectRevolutions: PropTypes.func.isRequired,
  requestActivityLog: PropTypes.func.isRequired,
  selectDialPosition: PropTypes.func.isRequired,
  user_id: PropTypes.number,
  selectedGender: PropTypes.string.isRequired,
  selectedAge: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  selectedHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  selectedWeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  selectedDialPosition: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  selectedDate: PropTypes.string.isRequired,
  dialPositions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired,
  distances: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired,
  times: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      text: PropTypes.string
    })
  ).isRequired,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.shape,
      text: PropTypes.string
    })
  ).isRequired,
  selectDistance: PropTypes.func.isRequired,
  selectTime: PropTypes.func.isRequired,
  selectDate: PropTypes.func.isRequired,
  selectedRevolutions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  errorMessage: PropTypes.string,
  activityLogErrorMessage: PropTypes.string
};

const mapStateToProps = ({ calculator, settings, user, activityLog }) => (
  {
    dialPositions: calculator.dialPositions,
    times: calculator.times,
    distances: calculator.distances,
    dates: calculator.dates,
    selectedDialPosition: calculator.selectedDialPosition,
    selectedTime: calculator.selectedTime,
    selectedDistance: calculator.selectedDistance,
    selectedRevolutions: calculator.selectedRevolutions,
    selectedDate: calculator.selectedDate,
    calculatorErrorMessage: calculator.calculatorErrorMessage,
    devices: settings.devices,
    genders: settings.genders,
    heights: settings.heights,
    weights: settings.weights,
    ages: settings.ages,
    selectedDevice: settings.selectedDevice,
    selectedGender: settings.selectedGender,
    selectedHeight: settings.selectedHeight,
    selectedWeight: settings.selectedWeight,
    selectedAge: settings.selectedAge,
    user_id: user.id,
    activityLogErrorMessage: activityLog.errorMessage
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    ...bindActionCreators(activityLogActions, dispatch),
    ...bindActionCreators(calculatorActions, dispatch)
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorSection);
