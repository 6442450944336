function setHeightsValues() {
  // Add initial heights
  const MIN_HEIGHT = 48;
  const MAX_HEIGHT = 83;

  const heights = [];

  for (let i = MIN_HEIGHT; i <= MAX_HEIGHT; i += 1) {
    const cm = (i * 2.54).toFixed(0);
    heights.push({
      value: i,
      text: `${Math.floor(i / 12)} ft ${i % 12} in (${Math.floor(cm / 100)}.${cm % 100} m)`
    });
  }

  return (heights);
}

function setWeightsValues() {
  // Add initial heights
  const MIN_WEIGHT = 90;
  const MAX_WEIGHT = 500;

  const weights = [];

  for (let i = MIN_WEIGHT; i <= MAX_WEIGHT; i += 1) {
    weights.push({
      value: i,
      text: `${i} lbs (${(i / 2.205).toFixed(0)} kg)`
    });
  }

  return (weights);
}

function setAgesValues() {
  // Add initial ages
  const MIN_AGE = 18;
  const MAX_AGE = 80;

  const ages = [];

  for (let i = MIN_AGE; i <= MAX_AGE; i += 1) {
    ages.push({
      value: i,
      text: `${i} years`
    });
  }

  return (ages);
}

const initialState = {
  devices: [
    {
      value: 'deskcycle', text: 'DeskCycle'
    },
    {
      value: 'deskcycle 2', text: 'DeskCycle 2'
    },
    {
      value: 'deskcycle ellipse', text: 'DeskCycle Ellipse'
    }
  ],
  genders: [
    {
      value: 'male', text: 'Male'
    },
    {
      value: 'female', text: 'Female'
    }
  ],
  heights: setHeightsValues(),
  weights: setWeightsValues(),
  ages: setAgesValues(),
  selectedDevice: 'deskcycle',
  selectedGender: 'male',
  selectedHeight: 70,
  selectedWeight: 150,
  selectedAge: 30
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_DEVICE':
    case 'SELECT_GENDER':
    case 'SELECT_HEIGHT':
    case 'SELECT_WEIGHT':
    case 'SELECT_AGE':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default settings;
