import { postRequest,
  putRequest,
  getRequest,
  deleteRequest } from '../utils/Requests';
import * as storage from '../utils/Storage';
import { requestMyProgress, resetMyProgress } from './progress';
import { requestMessage, deleteMessage } from './messaging';
import { RollbarErrorTracking } from '../components/errors/RollbarErrorTracking';

export const performLoginUser = (
  id, email, username, settings, fitbitConnected, fitbitDisplayName, fitbitValue, confirmed
) => ({
  type: 'PERFORM_LOGIN_USER',
  payload: {
    id,
    email,
    username,
    settings,
    fitbitConnected,
    fitbitDisplayName,
    fitbitValue,
    confirmed
  }
});

export const requestNewPassword = () => ({
  type: 'REQUEST_NEW_PASSWORD'
});

export const performLogoutUser = () => ({
  type: 'PERFORM_LOGOUT_USER'
});

export const performUpdateUserFitbitConnectedConnected = (fitbit_connected) => ({
  type: 'PERFORM_UPDATE_USER_FITBIT_CONNECTED',
  payload: {
    fitbitConnected: fitbit_connected
  }
});

export const performUpdateUser = (email, username, settings, fitbitConnected, fitbitDisplayName, fitbitValue, confirmed) => ({
  type: 'PERFORM_UPDATE_USER',
  payload: {
    email,
    username,
    settings,
    fitbitConnected,
    fitbitDisplayName,
    fitbitValue,
    confirmed
  }
});

export const addErrorMessage = (errorMessage) => ({
  type: 'ADD_ERROR_MESSAGE',
  payload: {
    errorMessage
  }
});

export const addSuccessMessage = (successMessage) => ({
  type: 'ADD_SUCCESS_MESSAGE',
  payload: {
    successMessage
  }
});

export const clearMessage = () => ({
  type: 'CLEAR_MESSAGE'
});

export const displayConfirmationMessage = () => ({
  type: 'DISPLAY_CONFIRMATION_MESSAGE'
});

export const hideConfirmationMessage = () => ({
  type: 'HIDE_CONFIRMATION_MESSAGE'
});

function parseUserData(response) {
  const {
    id, email, username, age, height_inches,
    weight_lbs, gender, fitbit_connected,
    fitbit_display_name, fitbit_value, confirmed
  } = response.data;
  const settings = {
    age,
    gender,
    height: height_inches,
    weight: weight_lbs
  };

  // Save JWToken
  if (response.headers.authorization !== undefined) {
    // This prevents reseting the authorization on redirects
    storage.setJWToken(response.headers.authorization);
  }

  return ({
    id,
    email,
    username,
    settings,
    fitbitConnected: fitbit_connected,
    fitbitDisplayName: fitbit_display_name,
    fitbitValue: fitbit_value,
    confirmed
  });
}

export const registerUser = (email, username, password, passwordConfirmation) => (dispatch) => postRequest('signup', {
  user: {
    email: email,
    username: username,
    password: password,
    password_confirmation: passwordConfirmation
  }
}).then((response) => {
  dispatch(addSuccessMessage(response.data.message));
}).catch((error) => {
  if (typeof error.response !== 'undefined') {
    dispatch(addErrorMessage(error.response.data.error));
  } else {
    RollbarErrorTracking.logErrorInRollbar('registerUser::signup', error.response);
  }
});

export const logoutUser = () => (dispatch) => {
  storage.clearJWToken();
  dispatch(performLogoutUser());
  dispatch(resetMyProgress());
  dispatch(deleteMessage('myProgress'));
};

export const updateUserFitbitConnected = (fitbit_connected) => (dispatch) => {
  // Connect/disconnect user from fitbit
  dispatch(performUpdateUserFitbitConnectedConnected(fitbit_connected));
};

export const disconnectFromFitbit = (userId) => (dispatch) => deleteRequest(
  `disconnect_from_fitbit/${userId}`
).then((response) => {
  const {
    email,
    username,
    settings,
    fitbitConnected,
    fitbitDisplayName,
    fitbitValue,
    confirmed
  } = parseUserData(response);

  dispatch(performUpdateUser(email, username, settings, fitbitConnected, fitbitDisplayName, fitbitValue, confirmed));
});

export const editUser = (userId, params) => (dispatch) => putRequest(
  `users/${userId}`, params
).then((response) => {
  const {
    email,
    username,
    settings,
    fitbitConnected,
    fitbitDisplayName,
    fitbitValue,
    confirmed
  } = parseUserData(response);

  dispatch(performUpdateUser(email, username, settings, fitbitConnected, fitbitDisplayName, fitbitValue, confirmed));
}).catch((error) => {
  if (typeof error.response !== 'undefined') {
    dispatch(addErrorMessage(error.response.data.error));
  } else {
    RollbarErrorTracking.logErrorInRollbar('updateUser::updateUser', error.response);
  }
});

export const updateUser = (userId) => (dispatch) => getRequest(
  `users/${userId}`
).then((response) => {
  const {
    email,
    username,
    settings,
    fitbitConnected,
    fitbitDisplayName,
    fitbitValue,
    confirmed
  } = parseUserData(response);

  dispatch(performUpdateUser(email, username, settings, fitbitConnected, fitbitDisplayName, fitbitValue, confirmed));
}).catch((error) => {
  if (typeof error.response !== 'undefined') {
    dispatch(addErrorMessage(error.response.data.error));
  } else {
    RollbarErrorTracking.logErrorInRollbar('updateUser::updateUser', error.response);
  }
});

export const loginUser = (email, password) => (dispatch) => postRequest('login', {
  user: {
    email,
    password
  }
}).then((response) => {
  const {
    id,
    email,
    username,
    settings,
    fitbitConnected,
    fitbitDisplayName,
    fitbitValue,
    confirmed
  } = parseUserData(response);

  dispatch(requestMyProgress());
  dispatch(requestMessage(id, 'myProgress'));
  dispatch(
    performLoginUser(id, email, username, settings, fitbitConnected, fitbitDisplayName, fitbitValue, confirmed)
  );
}).catch((error) => {
  if (typeof error.response !== 'undefined') {
    dispatch(addErrorMessage(error.response.data.error));
  } else {
    RollbarErrorTracking.logErrorInRollbar('updateUser::updateUser', error.response);
  }
});

export const requestPasswordReset = (email) => () => postRequest('password', {
  user: {
    email
  },
  requester: 'web'
});

export const enterPassword = (token, password, passwordConfirmation) => (dispatch) => putRequest('password', {
  user: {
    token,
    password,
    password_confirmation: passwordConfirmation
  }
}).then((response) => {
  dispatch(addSuccessMessage('Password updated successfully.'));
}).catch((error) => {
  if (typeof error.response !== 'undefined') {
    dispatch(addErrorMessage(error.response.data.error));
  } else {
    RollbarErrorTracking.logErrorInRollbar('enterPassword::password', error.response);
  }
});

export const requestConfirmationEmail = (userEmail, confirmationToken) => (dispatch) => postRequest('users/confirmation', {
  email: userEmail,
  confirmation_token: confirmationToken
}).then(() => {
  dispatch(addSuccessMessage('Email confirmed successfully.'));
  dispatch(displayConfirmationMessage());
}).catch((error) => {
  if (typeof error.response !== 'undefined') {
    dispatch(addErrorMessage(error.response.data.error));
    dispatch(displayConfirmationMessage());
  } else {
    RollbarErrorTracking.logErrorInRollbar('requestConfirmationEmail::confirmation', error.response);
  }
});
