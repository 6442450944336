import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../atoms/CustomModal';
import CustomButton from '../atoms/CustomButton';
import SelectedLogDetails from '../blocks/SelectedLogDetails';

class LogDeleteModal extends Component {
  handleClose = () => {
    const {
      onCloseModal,
      selectedLog
    } = this.props;

    onCloseModal(selectedLog);
  }

  handleDeleteSubmit = () => {
    const {
      onDeleteSubmit,
      selectedLog
    } = this.props;

    onDeleteSubmit(selectedLog.id);
  }

  render() {
    const {
      selectedLog,
      show,
      message
    } = this.props;

    return (
      <div className="log-delete-modal">
        <CustomModal
          show={show}
          onClose={this.handleClose}
          name="LogDeleteModal"
          title="Delete Log"
          message={message}
        >
          <div
            className="log-delete-form"
          >
            <p>
              Are you sure you want to delete the selected Log?
            </p>
            <br />
            {
              selectedLog
              && (
                <SelectedLogDetails
                log={selectedLog}
                />
              )
            }
            <hr />
            <CustomButton
              extraClasses="large mb-5"
              iconNames={['sign-in-alt']}
              iconClasses="mr-10 icon-red"
              buttonClick={this.handleDeleteSubmit}
            >
              <span>Yes, Delete The Log</span>
            </CustomButton>
            <CustomButton
              extraClasses="large"
              iconNames={[['far', 'times-circle']]}
              iconClasses="mr-10 icon-gray"
              buttonClick={this.handleClose}
            >
              <span>No, Cancel</span>
            </CustomButton>
          </div>
        </CustomModal>
      </div>
    );
  }
}

LogDeleteModal.defaultProps = {
  selectedLog: null,
  show: false,
  message: null
};

LogDeleteModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onDeleteSubmit: PropTypes.func.isRequired,
  selectedLog: PropTypes.shape({
    id: PropTypes.number,
    startedAt: PropTypes.string,
    seconds_pedaled: PropTypes.number,
    revolutions: PropTypes.number,
    total_calories: PropTypes.number
  }),
  show: PropTypes.bool,
  message: PropTypes.string
};

export default LogDeleteModal;
