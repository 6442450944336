function getWeekDay(date) {
  const weekDays = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
  ];
  const dayIndex = date.getDay();

  return weekDays[dayIndex];
}

function getMonth(date) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Set',
    'Oct',
    'Nov',
    'Dec'
  ];
  const monthIndex = date.getMonth();

  return months[monthIndex];
}

function formatTwoDigits(number) {
  // Make single digit hours or minutes have a two digits
  return (`0${number}`).slice(-2);
}

export function formatedDate(date) {
  // Convert date string to Date object without losing info
  const parsedDate = new Date(Date.parse(date));

  const weekDay = getWeekDay(parsedDate);
  const month = getMonth(parsedDate);
  const monthDay = parsedDate.getDate();
  const year = parsedDate.getFullYear();
  const hours = formatTwoDigits(parsedDate.getHours());
  const minutes = formatTwoDigits(parsedDate.getMinutes());

  return `${weekDay} - ${month} ${monthDay}, ${year} - ${hours}:${minutes}`;
}

export function isMigrationPeriod() {
  const today = new Date();
  const migrationStart = new Date(process.env.REACT_APP_MIGRATION_START);
  const migrationEnd = new Date(process.env.REACT_APP_MIGRATION_END);

  return migrationStart <= today && today <= migrationEnd;
}
