import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const headerInfo = [
  {
    iconName: 'fire',
    iconColor: 'icon-coral',
    title: 'Calories'
  },
  {
    iconName: 'bicycle',
    iconColor: 'icon-navy',
    title: 'Miles, Km'
  },
  {
    iconName: 'stopwatch',
    iconColor: 'icon-yellow',
    title: 'Minutes'
  },
  {
    iconName: 'shoe-prints',
    iconColor: 'icon-brown',
    title: 'Steps'
  }
];

function renderTableHeaderColumn(info) {
  return (
    <div
      className="header-column"
      key={info.title}
    >
      <FontAwesomeIcon
        className={info.iconColor}
        icon={info.iconName}
      />
      <span className="title">
        {info.title}
      </span>
    </div>
  );
}

function renderTableHeader() {
  return (
    <div className="progress-table-header">
      {
        headerInfo.map((info) => (
          renderTableHeaderColumn(info)
        ))
      }
    </div>
  );
}

function renderTableRow(info) {
  return (
    <div
      className="progress-table-row"
      key={info.label[0]}
    >
      <div
        className="row-header"
      >
        <span>
          {info.label[0]}
        </span>
        <span>
          {info.label[1]}
        </span>
      </div>
      <div
        className="row-data"
      >
        <div className="red">
          {info.calories_pedaling}
        </div>
        <div className="navy">
          {`${info.distance_mi}, ${info.distance_km}`}
        </div>
        <div className="yellow">
          {info.minutes_pedaled}
        </div>
        <div className="brown">
          {info.steps}
        </div>
      </div>
    </div>
  );
}

const ProgressTable = (props) => {
  const { data } = props;

  return (
    <div className="progress-table">
      {renderTableHeader()}
      <div className="progress-table-rows">
        {
          data.map((info) => (
            renderTableRow(info)
          ))
        }
      </div>
    </div>
  );
};

ProgressTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.arrayOf(
        PropTypes.string
      ).isRequired,
      calories_pedaling: PropTypes.number.isRequired,
      distance_mi: PropTypes.string.isRequired,
      distance_km: PropTypes.string.isRequired,
      minutes_pedaled: PropTypes.number.isRequired,
      steps: PropTypes.number.isRequired,
      miles_walked: PropTypes.string.isRequired,
      km_walked: PropTypes.string.isRequired,
      revolutions: PropTypes.number.isRequired
    })
  ).isRequired
};

export default ProgressTable;
