const initialState = {
  progress: {
    days: [],
    weeks: [],
    months: [],
    totals: null
  },
  loading: false
};

const progress = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_MY_PROGRESS':
      return {
        ...state,
        loading: true
      };
    case 'UPDATE_MY_PROGRESS':
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case 'RESET_PROGRESS':
      return {
        progress: {
          days: [],
          weeks: [],
          months: [],
          totals: null
        },
        loading: false
      };
    default:
      return state;
  }
};

export default progress;
