import React from 'react';
import PropTypes from 'prop-types';
import CustomOutput from '../atoms/CustomOutput';
import { formatedDate } from '../../utils/Dates';

const SelectedLogDetails = (props) => {
  const { log } = props;
  let minutes_pedaled = Math.floor(log.seconds_pedaled / 60);

  return (
    <div className="log-details">
      <h3>Selected Log Details</h3>
      <div className="date">
        {formatedDate(log.startedAt)}
      </div>
      <CustomOutput
        iconClasses="icon-yellow"
        iconName={['far', 'clock']}
      >
        <span>
          {`${minutes_pedaled} `}
          Minutes Pedaled
        </span>
      </CustomOutput>
      <CustomOutput
        iconClasses="icon-lightgreen"
        iconName="sync-alt"
      >
        <span>
          {`${log.revolutions} `}
          Pedal Revolutions
        </span>
      </CustomOutput>
      <CustomOutput
        iconClasses="icon-orange"
        iconName="fire"
      >
        <span>
          {`${log.total_calories} `}
          Calories Burned
        </span>
      </CustomOutput>
    </div>
  );
};

SelectedLogDetails.propTypes = {
  log: PropTypes.shape({
    startedAt: PropTypes.string,
    seconds_pedaled: PropTypes.number,
    revolutions: PropTypes.number,
    total_calories: PropTypes.number
  }).isRequired
};

export default SelectedLogDetails;
