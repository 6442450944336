import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomOutput = (props) => {
  const {
    iconClasses,
    iconName,
    extraClasses,
    children
  } = props;

  return (
    <div className={`custom-output ${extraClasses}`}>
      <span className="icon">
        <FontAwesomeIcon
          className={iconClasses}
          icon={iconName}
        />
      </span>
      {children}
    </div>
  );
};

CustomOutput.defaultProps = {
  iconClasses: '',
  iconName: '',
  extraClasses: ''
};

CustomOutput.propTypes = {
  iconClasses: PropTypes.string,
  iconName: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.string
    ),
    PropTypes.string
  ]).isRequired,
  children: PropTypes.element.isRequired,
  extraClasses: PropTypes.string
};

export default CustomOutput;
