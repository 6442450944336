import React from 'react';
import logo from '../../assets/images/deskcycle_logo.png';

const Header = () => (
  <header>
    <div className="logo-section">
      <a
        className="header-link"
        href="https://deskcycle.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logo} className="logo" alt="logo" />
      </a>
    </div>
  </header>
);

export default Header;
