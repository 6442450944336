import { combineReducers } from 'redux';
import activityLog from './activity_log';
import activityLogs from './activity_logs';
import calculator from './calculator';
import progress from './progress';
import messaging from './messaging';
import settings from './settings';
import user from './user';

const rootReducer = combineReducers({
  activityLog,
  activityLogs,
  calculator,
  messaging,
  progress,
  settings,
  user
});

export default rootReducer;
