import React from 'react';
import PropTypes from 'prop-types';
import CustomOutput from '../atoms/CustomOutput';

const ProgressTotals = (props) => {
  const { data } = props;

  if (data !== null) {
    return (
      <div className="progress-totals-details">
        <h3>All Time Totals</h3>
        <CustomOutput
          iconClasses="icon-yellow"
          iconName="sun"
          extraClasses="big"
        >
          <span>
            <span className="output-title">Days: </span>
            {data.days}
          </span>
        </CustomOutput>
        <CustomOutput
          iconClasses="icon-orange"
          iconName="fire"
          extraClasses="big"
        >
          <span>
            <span className="output-title">Calories Burned Pedaling: </span>
            {data.calories_pedaling}
          </span>
        </CustomOutput>
        <CustomOutput
          iconClasses="icon-navy"
          iconName="bicycle"
          extraClasses="big"
        >
          <span>
            <span className="output-title">Distance: </span>
            {`${data.distance_mi} `}
            (miles),
            {` ${data.distance_km} `}
            (km)
          </span>
        </CustomOutput>
        <CustomOutput
          iconClasses="icon-yellow"
          iconName={['far', 'clock']}
          extraClasses="big"
        >
          <span>
            <span className="output-title">Minutes: </span>
            {data.minutes}
          </span>
        </CustomOutput>
        <CustomOutput
          iconClasses="icon-brown"
          iconName="shoe-prints"
          extraClasses="big"
        >
          <span>
            <span className="output-title">Steps: </span>
            {data.steps}
          </span>
        </CustomOutput>
        <CustomOutput
          iconClasses="icon-brown"
          iconName="shoe-prints"
          extraClasses="big"
        >
          <span>
            <span className="output-title">Distance: </span>
            {`${data.miles_walked} `}
            (miles),
            {` ${data.km_walked} `}
            (km)
          </span>
        </CustomOutput>
        <CustomOutput
          iconClasses="icon-lightgreen"
          iconName="sync-alt"
          extraClasses="big"
        >
          <span>
            <span className="output-title">Revolutions: </span>
            {data.revolutions}
          </span>
        </CustomOutput>
      </div>
    );
  }

  return (
    <div className="progress-totals-details" />
  );
};

ProgressTotals.propTypes = {
  data: PropTypes.shape({
    days: PropTypes.number.isRequired,
    calories_pedaling: PropTypes.number.isRequired,
    distance_mi: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    distance_km: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    minutes: PropTypes.number.isRequired,
    steps: PropTypes.number.isRequired,
    miles_walked: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    km_walked: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]).isRequired,
    revolutions: PropTypes.number.isRequired
  }).isRequired
};

export default ProgressTotals;
