import { getRequest } from '../utils/Requests';

export const fetchMyProgress = () => ({
  type: 'FETCH_MY_PROGRESS'
});

export const updateMyProgress = (progress) => ({
  type: 'UPDATE_MY_PROGRESS',
  payload: {
    progress
  }
});

export const resetProgress = () => ({
  type: 'RESET_PROGRESS'
});

export const requestMyProgress = () => (dispatch) => {
  dispatch(fetchMyProgress());

  return getRequest(
    'activity_logs/my_progress'
  ).then((response) => {
    dispatch(updateMyProgress(response.data));
  });
};

export const resetMyProgress = () => (dispatch) => {
  dispatch(resetProgress());
};
