export const selectDevice = (selectedDevice) => ({
  type: 'SELECT_DEVICE',
  payload: {
    selectedDevice
  }
});

export const selectGender = (selectedGender) => ({
  type: 'SELECT_GENDER',
  payload: {
    selectedGender
  }
});

export const selectHeight = (selectedHeight) => ({
  type: 'SELECT_HEIGHT',
  payload: {
    selectedHeight
  }
});

export const selectWeight = (selectedWeight) => ({
  type: 'SELECT_WEIGHT',
  payload: {
    selectedWeight
  }
});

export const selectAge = (selectedAge) => ({
  type: 'SELECT_AGE',
  payload: {
    selectedAge
  }
});
