// How to use?
// import RollbarErrorTracking from '../RollbarErrorTracking';
// RollbarErrorTracking.logErrorInRollbar(error.response.data.message);

import Rollbar from 'rollbar';

export const RollbarErrorTracking = (() => {
  const RollbarObj = new Rollbar({
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ENV
    }
  });

  const logErroInfo = (info) => {
    RollbarObj.info(info);
  };

  const logErrorInRollbar = (error) => {
    try {
      throw new Error(error);
    } catch (err) {
      // already caught if possible
    }
  };

  return {
    logErroInfo, logErrorInRollbar
  };
})();

export default RollbarErrorTracking;
