import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../atoms/CustomModal';
import CustomButton from '../atoms/CustomButton';

class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };
  }

  handleChange = (input) => (event) => {
    this.setState({
      [input]: event.target.value,
    });
  }

  handleLoginSubmit = () => {
    const { onSubmitLoginModal } = this.props;
    const {
      password,
      email
    } = this.state;

    onSubmitLoginModal(email, password);
  }

  renderLinks() {
    const {
      openForgotPassword,
      openCreateAccount
    } = this.props;

    return (
      <div className="links">
        <CustomButton
          extraClasses="link mr-10"
          iconNames={['key']}
          iconClasses="mr-5"
          buttonClick={openForgotPassword}
        >
          <span>Forgot Password</span>
        </CustomButton>
        <CustomButton
          extraClasses="link"
          iconNames={['user-circle']}
          iconClasses="mr-5"
          buttonClick={openCreateAccount}
        >
          <span>Create Account</span>
        </CustomButton>
      </div>
    );
  }

  render() {
    const {
      show,
      onCloseModal,
      errorMessage
    } = this.props;

    const {
      password,
      email
    } = this.state;

    return (
      <div className="login-modal">
        <CustomModal
          show={show}
          onClose={onCloseModal}
          name="LoginModal"
          title="Calculator Login"
          messageClasses="error"
          message={errorMessage}
        >
          <div
            className="login-form"
          >
            <label>Email Address</label>
            <input
              type="text"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={this.handleChange('email')}
            />
            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={this.handleChange('password')}
            />
            <hr />
            <CustomButton
              extraClasses="submit large"
              type="submit"
              iconNames={['sign-in-alt']}
              iconClasses="mr-10"
              iconColor="white"
              buttonClick={this.handleLoginSubmit}
            >
              <span>Login</span>
            </CustomButton>
            {this.renderLinks()}
          </div>
        </CustomModal>
      </div>
    );
  }
}

LoginModal.defaultProps = {
  show: false,
  errorMessage: null
};

LoginModal.propTypes = {
  onSubmitLoginModal: PropTypes.func.isRequired,
  openForgotPassword: PropTypes.func.isRequired,
  openCreateAccount: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default LoginModal;
