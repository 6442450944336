import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import titleImg from '../../assets/images/progress_header.png';

class CustomModal extends Component {
  handleCloseClick = (event) => {
    const {
      name,
      onClose
    } = this.props;

    event.preventDefault();
    onClose(name);
  }

  renderTitle() {
    const {
      title,
      titleImgName
    } = this.props;

    if (titleImgName) {
      return (
        <div className="title">
          <img
            src={titleImg}
            className="title-img"
            alt={titleImgName}
          />
        </div>
      );
    }

    return (
      <div className="title">
        <h2>{title}</h2>
      </div>
    );
  }

  renderMessage() {
    const {
      messageClasses,
      message
    } = this.props;

    return (
      <div className={`message ${messageClasses}`}>
        {message}
      </div>
    );
  }

  render() {
    const {
      show,
      extraClasses,
      message,
      children
    } = this.props;

    return (
      <div className={`modal-container ${show ? 'show' : ''}`}>
        <div className={`custom-modal ${extraClasses}`}>
          <div className="header">
            <button
              type="button"
              className="modal-close"
              onClick={this.handleCloseClick}
            >
              <FontAwesomeIcon
                icon="times"
              />
            </button>
            {this.renderTitle()}
          </div>
          {message && this.renderMessage()}
          <div className="body">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

CustomModal.defaultProps = {
  titleImgName: '',
  messageClasses: '',
  message: '',
  extraClasses: '',
  show: false
};

CustomModal.propTypes = {
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleImgName: PropTypes.string,
  messageClasses: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
  extraClasses: PropTypes.string,
  children: PropTypes.element.isRequired
};

export default CustomModal;
