import React from 'react';

const Info = () => (
  <div className="info">
    <h2>Why do we have a Calorie Calculator?</h2>
    <h3>Cycling displays are not accurate.</h3>
    <p>
      Most exercise bikes display Calories and Distance. Unfortunately they are only
      estimates. The reasons for the inaccuracy in Calories and Distance are,
    </p>
    <ul>
      <li>they don&apos;t measure the actual power you put into the bike</li>
      <li>they don&apos;t take into account the person using the bike</li>
    </ul>
    <p>
      Without knowing the person&apos;s weight, the Calorie estimate could be off by more
      than 100%. Without knowing power, the Calorie estimates can be off by as much
      as 1000%.
    </p>
    <h3>Why don&apos;t they measure power?</h3>
    <p>
      Because it&apos;s expensive to measure power. Measuring power could easily add over
      $200 to the cost of the bike.
    </p>
    <h3>Our solution</h3>
    <p>
      We calibrate our bikes at the factory. The resistance is known for each position
      of the adjustment dial at all pedal speeds. We also include the physical
      characteristics of the person using the bike to improve the Calorie and Distance
      calculation.
    </p>
    <h3>Progress Logging and Fitbit&reg; Support</h3>
    <p>
      Keep your momentum going by logging and viewing your exercise progress. Using the
      Progress Viewer you can view your daily, weekly and monthly progress. Simply create
      an account and then save your daily progress with a click of a button in the Calorie
      Calculator Results window.
    </p>
    <p>
      You can also save your daily DeskCycle usage to your Fitbit® account as either
      footsteps, or cycling miles on a road bike. The activity you save on your DeskCycle app
      is transmitted to Fitbit via an asynchronous transmission so it may not appear in your
      Fitbit account right away.
    </p>
  </div>
);

export default Info;
