import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../atoms/CustomButton';
import ConfirmationModal from '../blocks/ConfirmationModal';
import LoginModal from '../blocks/LoginModal';
import RegisterModal from '../blocks/RegisterModal';
import EnterPasswordModal from '../blocks/EnterPasswordModal';
import ForgotPasswordModal from '../blocks/ForgotPasswordModal';
import LogEditorModal from '../blocks/LogEditorModal';
import LogDeleteModal from '../blocks/LogDeleteModal';
import MyProgressModal from '../blocks/MyProgressModal';
import ProgressViewer from '../blocks/ProgressViewer';
import * as userActions from '../../actions/user';
import * as activityLogsActions from '../../actions/activity_logs';
import * as activityLogActions from '../../actions/activity_log';
import * as progressActions from '../../actions/progress';
import fitbitLogo from '../../assets/images/fitbit_logo.png';
import fitbitLogoShort from '../../assets/images/fitbit_logo_short.png';

function setResetPasswordToken() {
  // Check url to see if we have a user[token] param, if so and if the user is
  // not logged in, show the EnterPasswordModal
  const params = new URLSearchParams(window.location.search.substring());

  return params.get('user[token]');
}

class MyAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoginModal: false,
      showRegisterModal: false,
      showForgotPasswordModal: false,
      resetPasswordToken: setResetPasswordToken(),
      showLogEditorModal: false,
      showLogDeleteModal: false,
      showMyProgressModal: false,
      selectedLog: null
    };
    const {
      loggedIn
    } = props;

    if (!loggedIn) {
      // Check for confirmation token if user is not logged in
      this.confirmationToken();
    } else {
      // Check if the user is connected to fitbit and update that info
      this.fitbitConnected();
    }
  }

  componentDidUpdate(prevProps) {
    const { loggedIn } = this.props;

    if (prevProps.loggedIn && prevProps.loggedIn !== loggedIn) {
      // Keep modals closed after user logged out
      this.setState({
        showLoginModal: false,
        showRegisterModal: false,
        showForgotPasswordModal: false,
        resetPasswordToken: null
      });
    }
  }

  confirmationToken() {
    // Check url to see if we have a confirmation_token param, if so and if the user is
    // not logged in, request email confirmation
    const params = new URLSearchParams(window.location.search.substring());

    const confirmationToken = params.get('confirmation_token');
    if (confirmationToken) {
      const {
        requestConfirmationEmail
      } = this.props;
      const email = params.get('email');

      requestConfirmationEmail(email, confirmationToken);
    }
  }

  fitbitConnected() {
    if (window.location.pathname === '/login_fitbit') {
      const {
        updateUser,
        userId
      } = this.props;
      updateUser(userId);
    }
  }

  buttonText() {
    const { loggedIn } = this.props;
    const text = loggedIn ? 'Logout' : 'Login or Create Account';

    return text;
  }

  userInfoText() {
    const {
      loggedIn,
      username
    } = this.props;

    const infoText = loggedIn ? `Logged in as ${username}` : 'Logged Out';

    return infoText;
  }

  iconName() {
    const { loggedIn } = this.props;
    const iconName = loggedIn ? 'sign-out-alt' : 'sign-in-alt';

    return iconName;
  }

  iconColor() {
    const { loggedIn } = this.props;
    const iconColor = loggedIn ? 'icon-yellow' : 'icon-green';

    return iconColor;
  }

  openLoginModal() {
    const { clearMessage } = this.props;

    this.setState({
      showLoginModal: true
    });
    clearMessage();
  }

  handleMyAccountButtonClick = () => {
    const {
      loggedIn,
      logoutUser,
      clearMessage
    } = this.props;

    if (loggedIn) {
      logoutUser();
      clearMessage();
    } else {
      this.openLoginModal();
    }
  }

  handleCloseModal = (modalName) => {
    const { clearMessage } = this.props;

    this.setState({
      [`show${modalName}`]: false
    });
    clearMessage();
  }

  handleCloseEnterPasswordModal = () => {
    const { clearMessage } = this.props;

    this.setState({
      resetPasswordToken: null,
      showLoginModal: true
    });
    clearMessage();
  }

  handleCloseLogDeleteModal = (selectedLog) => {
    this.setState({
      showLogDeleteModal: false,
      showLogEditorModal: true,
      selectedLog
    });
  }

  handleCloseMyProgressModal = () => {
    this.setState({
      showMyProgressModal: false
    });
  }

  handleDisconnectFromFitbitClick = () => {
    const {
      disconnectFromFitbit,
      userId
    } = this.props;

    disconnectFromFitbit(userId);
  }

  handleSubmitLoginModal = (email, password) => {
    const {
      loginUser,
      hideConfirmationMessage
     } = this.props;

    hideConfirmationMessage();
    loginUser(email, password);
  }

  handleSubmitRegisterModal = (email, username, password, passwordConfirmation) => {
    const {
      registerUser,
      hideConfirmationMessage
    } = this.props;

    hideConfirmationMessage();
    registerUser(
      email,
      username,
      password,
      passwordConfirmation
    );
  }

  handleSubmitEnterPasswordModal = (password, passwordConfirmation) => {
    const {
      enterPassword,
      hideConfirmationMessage
    } = this.props;

    hideConfirmationMessage();
    enterPassword(
      this.state.resetPasswordToken,
      password,
      passwordConfirmation
    );
  }

  handleSubmitForgotPasswordModal = (email) => {
    const {
      requestPasswordReset,
      hideConfirmationMessage
    } = this.props;

    hideConfirmationMessage();
    requestPasswordReset(email);
  }

  handleCloseConfirmationModal = () => {
    const {
      hideConfirmationMessage
    } = this.props;

    hideConfirmationMessage();
    this.setState({
      showLoginModal: true
    });
  }

  handleOpenForgotPassword = () => {
    this.setState({
      showLoginModal: false,
      showForgotPasswordModal: true
    });
  }

  handleOpenRegister = () => {
    this.setState({
      showLoginModal: false,
      showRegisterModal: true
    });
  }

  handleSaveResults = () => {
    const { requestSaveActivityLog } = this.props;

    requestSaveActivityLog();
  }

  handleOpenMyProgress = () => {
    const {
      requestMyProgress,
      progress
    } = this.props;

    if (progress.days.length === 0) {
      // Request only if the progress is empty
      requestMyProgress();
    }
    this.setState({
      showMyProgressModal: true
    })
  }

  handleOpenEditLogs = () => {
    // Request activity logs
    const { requestActivityLogs } = this.props;

    requestActivityLogs();
    this.setState({
       showLogEditorModal: true,
       showLogDeleteModal: false
     });
  }

  handleLogSubmit = (selectedLog) => {
    this.setState({
      selectedLog,
      showLogEditorModal: false,
      showLogDeleteModal: true
    });
  }

  handleLogDeleteSubmit = (deletedLogId) => {
    const { deleteActivityLog } = this.props;

    deleteActivityLog(deletedLogId);
    this.setState({
      showLogDeleteModal: false,
      showLogEditorModal: true,
      selectedLog: null
    });
  }

  handleFitbitOptionSelect = (option) => {
    const {
      editUser,
      userId
    } = this.props;

    // Edit user on the API side to save the selected fitbit option
    editUser(userId, { fitbit_value: option });
  }

  renderMyAccountButton() {
    return (
      <CustomButton
        className="default"
        iconClasses={this.iconColor()}
        iconNames={[this.iconName()]}
        buttonClick={this.handleMyAccountButtonClick}
      >
        <span>{this.buttonText()}</span>
      </CustomButton>
    );
  }

  renderModals() {
    const {
      loggedIn,
      userErrorMessage,
      userSuccessMessage,
      showConfirmationMessage,
      activityLogsList,
      progress,
      messages
    } = this.props;
    const {
      showLoginModal,
      showRegisterModal,
      resetPasswordToken,
      showForgotPasswordModal,
      showLogEditorModal,
      showLogDeleteModal,
      showMyProgressModal,
      selectedLog
    } = this.state;

    return (
      <div className="modals">
        <LoginModal
          show={showLoginModal && !loggedIn}
          onCloseModal={this.handleCloseModal}
          onSubmitLoginModal={this.handleSubmitLoginModal}
          openForgotPassword={this.handleOpenForgotPassword}
          openCreateAccount={this.handleOpenRegister}
          errorMessage={userErrorMessage}
        />
        <RegisterModal
          show={showRegisterModal && !loggedIn}
          onCloseModal={this.handleCloseModal}
          onSubmitRegisterModal={this.handleSubmitRegisterModal}
          errorMessage={userErrorMessage}
          successMessage={userSuccessMessage}
        />
        <EnterPasswordModal
          show={resetPasswordToken && !loggedIn}
          onCloseModal={this.handleCloseEnterPasswordModal}
          onSubmitEnterPasswordModal={this.handleSubmitEnterPasswordModal}
          errorMessage={userErrorMessage}
          successMessage={userSuccessMessage}
        />
        <ForgotPasswordModal
          show={showForgotPasswordModal && !loggedIn}
          onCloseModal={this.handleCloseModal}
          onSubmitForgotPasswordModal={this.handleSubmitForgotPasswordModal}
        />
        <LogEditorModal
          show={showLogEditorModal && loggedIn}
          onCloseModal={this.handleCloseModal}
          onLogSubmit={this.handleLogSubmit}
          activityLogs={activityLogsList}
          selectedLog={selectedLog}
        />
        <LogDeleteModal
          show={showLogDeleteModal && loggedIn}
          onCloseModal={this.handleCloseLogDeleteModal}
          onDeleteSubmit={this.handleLogDeleteSubmit}
          selectedLog={selectedLog}
        />
        <MyProgressModal
          show={showMyProgressModal && loggedIn}
          onCloseModal={this.handleCloseMyProgressModal}
          progress={progress}
          messages={messages}
        />
        <ConfirmationModal
          show={showConfirmationMessage}
          errorMessage={userErrorMessage}
          successMessage={userSuccessMessage}
          onCloseModal={this.handleCloseConfirmationModal}
        />
      </div>
    );
  }

  renderInfo() {
    const {
      loggedIn,
      showResults,
      fitbitConnected,
      fitbitValue
    } = this.props;

    if (!loggedIn) {
      return (
        <div className="logged-out-info">
          <p>You must be logged in to:</p>
          <ul>
            <li>Save your progress to your account.</li>
            <li>View your progress.</li>
            <li>Log Footsteps to your Fitbit account.</li>
          </ul>
        </div>
      );
    }
    return (
      <ProgressViewer
        onFitbitOptionSelect={this.handleFitbitOptionSelect}
        fitbitConnected={fitbitConnected}
        fitbitValue={fitbitValue}
        saveResults={this.handleSaveResults}
        openMyProgress={this.handleOpenMyProgress}
        openEditLogs={this.handleOpenEditLogs}
        showResults={showResults}
      />
    );
  }

  renderFitbitButton() {
    const {
      fitbitConnected,
      email,
      fitbitDisplayName
    } = this.props;

    if (fitbitConnected) {
      return (
        <div className="fitbit-info">
          <img
            src={fitbitLogo}
            className="fitbit-logo mr-15"
            alt="fitbit-logo"
          />
          <div className="fitbit-account">

            {fitbitDisplayName ? `Connected to ${fitbitDisplayName}'s account` : 'Connected to Fitbit'}
            <CustomButton
              extraClasses="disconnect-button"
              buttonClick={this.handleDisconnectFromFitbitClick}
            >
              <span>Disconnect</span>
            </CustomButton>
          </div>
        </div>
      );
    }

    return (
      <div className="fitbit-info column">
        <p>
          Connecting to Fitbit will enable posting your DeskCycle activity to
          Fitbit as footsteps or miles ridden on a bike.
        </p>
        <a
          className="fitbit-button btn"
          href={`https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_FITBIT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FITBIT_API_CALLBACK}&scope=activity%20profile&state=${email}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={fitbitLogoShort}
            className="fitbit-logo-short"
            alt="fitbit"
          />
          <span className="text">Connect To Fitbit</span>
        </a>
      </div>
    );
  }

  renderFitbitInfo() {
    return (
      <div className="fitbit-section">
        <h3>Connect to other applications</h3>
        {this.renderFitbitButton()}
      </div>
    );
  }

  render() {
    const { loggedIn } = this.props;

    return (
      <div className="my-account">
        <FontAwesomeIcon
          className="icon-blue mr-5"
          icon="user"
        />
        <span className="strong mr-5">My Account</span>
        <span className="blue mr-5 small">{this.userInfoText()}</span>
        {this.renderMyAccountButton()}
        {this.renderInfo()}
        {loggedIn && this.renderFitbitInfo()}
        {this.renderModals()}
      </div>
    );
  }
}

MyAccount.defaultProps = {
  username: null,
  userErrorMessage: null,
  activityLogsList: [],
  showResults: false
};

MyAccount.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  username: PropTypes.string,
  clearMessage: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  registerUser: PropTypes.func.isRequired,
  disconnectFromFitbit: PropTypes.func.isRequired,
  requestConfirmationEmail: PropTypes.func.isRequired,
  enterPassword: PropTypes.func.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
  requestSaveActivityLog: PropTypes.func.isRequired,
  requestActivityLogs: PropTypes.func.isRequired,
  requestMyProgress: PropTypes.func.isRequired,
  deleteActivityLog: PropTypes.func.isRequired,
  userErrorMessage: PropTypes.string,
  userSuccessMessage: PropTypes.string,
  hideConfirmationMessage: PropTypes.func.isRequired,
  activityLogsList: PropTypes.arrayOf(
    PropTypes.shape
  ),
  showResults: PropTypes.bool,
  email: PropTypes.string,
  fitbitConnected: PropTypes.bool.isRequired,
  fitbitValue: PropTypes.string.isRequired
};

const mapStateToProps = ({ user, activityLog, activityLogs, progress, messaging }) => (
  {
    loggedIn: user.loggedIn,
    userId: user.id,
    username: user.username,
    email: user.email,
    fitbitConnected: user.fitbitConnected,
    fitbitDisplayName: user.fitbitDisplayName,
    userErrorMessage: user.errorMessage,
    userSuccessMessage: user.successMessage,
    showConfirmationMessage: user.showConfirmationMessage,
    fitbitValue: user.fitbitValue,
    showResults: activityLog.showResults,
    activityLogsList: activityLogs.list,
    progress: progress.progress,
    messages: messaging.messages
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    ...bindActionCreators(userActions, dispatch),
    ...bindActionCreators(activityLogsActions, dispatch),
    ...bindActionCreators(activityLogActions, dispatch),
    ...bindActionCreators(progressActions, dispatch)
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
