import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../atoms/CustomModal';
import CustomButton from '../atoms/CustomButton';
import TabCollection from './TabCollection';
import ProgressTable from './ProgressTable';
import ProgressTotals from './ProgressTotals';

const tabsInfo = [
  {
    name: 'Days'
  },
  {
    name: 'Weeks'
  },
  {
    name: 'Months'
  },
  {
    name: 'All Time'
  }
];

class MyProgressModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'Days'
    };
  }

  handleTabSelect = (selectedTab) => {
    this.setState({selectedTab});
  }

  renderProgressTable(dataSet) {
    const { progress } = this.props;

    return (
      <ProgressTable
        data={progress[dataSet]}
      />
    );
  }

  renderProgressTotals() {
    const { progress } = this.props;

    return (
      <ProgressTotals
        data={progress.totals}
      />
    );
  }

  renderMyProgressScreen() {
    const { selectedTab } = this.state;

    switch (selectedTab) {
      case 'Days':
        return this.renderProgressTable('days');
      case 'Weeks':
        return this.renderProgressTable('weeks');
      case 'Months':
        return this.renderProgressTable('months');
      case 'All Time':
        return this.renderProgressTotals();
      default:
        return this.renderProgressTable('days');
    }
  }

  render() {
    const {
      show,
      onCloseModal,
      messages
    } = this.props;
    const { selectedTab } = this.state;

    return (
      <div className="my-progress-modal">
        <CustomModal
          show={show}
          onClose={onCloseModal}
          name="MyProgressModal"
          title="My Progress"
          titleImgName="progress_header"
        >
          <div>
            { messages.myProgress.message !== '' &&
              <div className={`messaging ${messages.myProgress.status}`}>
                {messages.myProgress.message}
              </div>
            }
            <div className="my-progress">
              <TabCollection
                collection={tabsInfo}
                selected={selectedTab}
                onTabSelect={this.handleTabSelect}
              />
              <div className="my-progress-area">
                {this.renderMyProgressScreen()}
              </div>
            </div>
            <hr />
            <CustomButton
              extraClasses="large"
              iconNames={[['far', 'times-circle']]}
              iconClasses="mr-10 icon-gray"
              buttonClick={onCloseModal}
            >
              <span>Close</span>
            </CustomButton>
          </div>
        </CustomModal>
      </div>
    );
  }
}

MyProgressModal.propTypes = {
  progress: PropTypes.shape({
    days: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.arrayOf(
          PropTypes.string
        ).isRequired,
        calories_pedaling: PropTypes.number.isRequired,
        distance_mi: PropTypes.string.isRequired,
        distance_km: PropTypes.string.isRequired,
        minutes_pedaled: PropTypes.number.isRequired,
        steps: PropTypes.number.isRequired,
        miles_walked: PropTypes.string.isRequired,
        km_walked: PropTypes.string.isRequired,
        revolutions: PropTypes.number.isRequired
      })
    ).isRequired,
    weeks: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.arrayOf(
          PropTypes.string
        ).isRequired,
        calories_pedaling: PropTypes.number.isRequired,
        distance_mi: PropTypes.string.isRequired,
        distance_km: PropTypes.string.isRequired,
        minutes_pedaled: PropTypes.number.isRequired,
        steps: PropTypes.number.isRequired,
        miles_walked: PropTypes.string.isRequired,
        km_walked: PropTypes.string.isRequired,
        revolutions: PropTypes.number.isRequired
      })
    ).isRequired,
    months: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.arrayOf(
          PropTypes.string
        ).isRequired,
        calories_pedaling: PropTypes.number.isRequired,
        distance_mi: PropTypes.string.isRequired,
        distance_km: PropTypes.string.isRequired,
        minutes_pedaled: PropTypes.number.isRequired,
        steps: PropTypes.number.isRequired,
        miles_walked: PropTypes.string.isRequired,
        km_walked: PropTypes.string.isRequired,
        revolutions: PropTypes.number.isRequired
      })
    ).isRequired,
    totals: PropTypes.shape({
      days: PropTypes.number,
      calories_pedaling: PropTypes.number,
      distance_mi: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      distance_km: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      minutes: PropTypes.number,
      steps: PropTypes.number,
      miles_walked: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      km_walked: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ]),
      revolutions: PropTypes.number
    })
  }),
  messages: PropTypes.shape({
    myProgress: PropTypes.shape({
      message: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

export default MyProgressModal;
