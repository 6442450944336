import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../atoms/CustomButton';
import CustomSelect from '../atoms/CustomSelect';

class ProgressViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFitbitOptions: true
    };
  }

  onSaveResults = () => {
    const { saveResults } = this.props;

    saveResults();
  }

  onViewMyProgress = () => {
    const { openMyProgress } = this.props;

    openMyProgress();
  }

  onEditLogs = () => {
    const { openEditLogs } = this.props;

    openEditLogs();
  }

  toggleAccountSettings = () => {
    const { showFitbitOptions } = this.state;

    this.setState({
      showFitbitOptions: !showFitbitOptions
    });
  }

  renderFitbitOptionsSelect() {
    const {
      onFitbitOptionSelect,
      fitbitValue
    } = this.props;

    const options = [
      {
        text: 'Do not log to Fitbit',
        value: 'none',
        selected: fitbitValue === 'none'
      },
      {
        text: 'Log steps to Fitbit',
        value: 'steps',
        selected: fitbitValue === 'steps'
      },
      {
        text: 'Log cycling distance to Fitbit',
        value: 'cycle',
        selected: fitbitValue === 'cycle'
      }
    ];

    return (
      <CustomSelect
        options={options}
        value={fitbitValue}
        valueSelected={onFitbitOptionSelect}
        name="fitbit-options"
      />
    );
  }

  renderFitbitOptions() {
    const { fitbitConnected } = this.props;

    if (fitbitConnected) {
      return (
        <div
          className="fitbit-options mb-15"
        >
          <p className="title">Fitbit Options</p>
          {this.renderFitbitOptionsSelect()}
        </div>
      );
    }

    return (
      <div
        className="fitbit-options mb-15"
      >
        <p className="title">Fitbit Options</p>
        <p>
          Fitbit logging options will be displayed here after you&apos;ve connected
          the Calorie Calculator to your Fitbit account. To do this, close this
          Results window, and then click on the
          <em> Connect to Fitbit </em>
          button in the
          <em> Account Settings </em>
          area.
        </p>
      </div>
    );
  }

  renderSaveResultsButton() {
    return (
      <div>
        <CustomButton
          extraClasses="large mb-5"
          iconNames={['save']}
          iconClasses="mr-10 icon-green"
          buttonClick={this.onSaveResults}
        >
          <span>Save Results to My Account</span>
        </CustomButton>
      </div>
    );
  }

  renderTitle() {
    const { showResults } = this.props;
    const { showFitbitOptions } = this.state;

    if (showResults) {
      return (
        <div
          className="account-settings mb-5"
        >
          <CustomButton
            iconNames={['cog']}
            iconClasses="icon-blue"
            buttonClick={this.toggleAccountSettings}
          >
            <span>Account Settings</span>
          </CustomButton>
          <div
            className={`options-container ${showFitbitOptions ? 'show' : ''}`}
          >
            {this.renderFitbitOptions()}
          </div>
        </div>
      );
    }

    return (
      <h3>Progress Viewer</h3>
    );
  }

  render() {
    const { showResults } = this.props;

    return (
      <div className="progress-viewer">
        {this.renderTitle()}
        <div className="buttons">
          { showResults && this.renderSaveResultsButton()}
          <CustomButton
            extraClasses="large mb-5"
            iconNames={['eye']}
            iconClasses="icon-vivid-blue mr-10"
            buttonClick={this.onViewMyProgress}
          >
            <span>View My Progress</span>
          </CustomButton>
          <CustomButton
            extraClasses="large"
            iconNames={['edit']}
            iconClasses="mr-10 icon-red"
            buttonClick={this.onEditLogs}
          >
            <span>Edit Logs</span>
          </CustomButton>
        </div>
      </div>
    );
  }
}

ProgressViewer.defaultProps = {
  showResults: false
};

ProgressViewer.propTypes = {
  saveResults: PropTypes.func.isRequired,
  openMyProgress: PropTypes.func.isRequired,
  openEditLogs: PropTypes.func.isRequired,
  showResults: PropTypes.bool,
  onFitbitOptionSelect: PropTypes.func.isRequired,
  fitbitValue: PropTypes.string.isRequired,
  fitbitConnected: PropTypes.bool.isRequired
};

export default ProgressViewer;
