const initialState = {
  loggedIn: false,
  username: null,
  email: null,
  id: null,
  fitbitConnected: false,
  fitbitDisplayName: null,
  fitbitValue: 'none',
  settings: {
    age: 30,
    gender: 'male',
    height: 70,
    weight: 150
  },
  errorMessage: '',
  successMessage: '',
  showConfirmationMessage: false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'PERFORM_LOGIN_USER':
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
        errorMessage: ''
      };
    case 'PERFORM_UPDATE_USER_FITBIT_CONNECTED':
      return {
        ...state,
        ...action.payload
      };
    case 'PERFORM_UPDATE_USER':
      return {
        ...state,
        ...action.payload
      };
    case 'REQUEST_FORGOT_PASSWORD':
      return {
        ...state
      };
    case 'REQUEST_NEW_PASSWORD':
      return {
        ...state
      };
    case 'PERFORM_LOGOUT_USER':
      return {
        loggedIn: false,
        username: null,
        email: null,
        fitbitConnected: false,
        fitbitValue: 'none',
        errorMessage: ''
      };
    case 'ADD_ERROR_MESSAGE':
      return {
        ...state,
        ...action.payload
      };
    case 'ADD_SUCCESS_MESSAGE':
      return {
        ...state,
        ...action.payload
      };
    case 'CLEAR_MESSAGE':
      return {
        ...state,
        errorMessage: '',
        successMessage: ''
      };
    case 'DISPLAY_CONFIRMATION_MESSAGE':
      return {
        ...state,
        showConfirmationMessage: true
      };
    case 'HIDE_CONFIRMATION_MESSAGE':
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        showConfirmationMessage: false
      };
    default:
      return state;
  }
};

export default user;
