import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatedDate } from '../../utils/Dates';

class CustomSelect extends Component {
  handleSelectChange = (event) => {
    const { valueSelected } = this.props;

    valueSelected(event.target.value);
  }

  renderLabel() {
    const {
      labelText,
      labelInfo
    } = this.props;

    return (
      <div className="label-section">
        <label>
          {labelText}
        </label>
        <div className="label-info">
          {labelInfo}
        </div>
      </div>
    );
  }

  renderOptionText(text) {
    // Format the select option text
    const { optionsFormat } = this.props;

    if (optionsFormat === 'formatedDate') {
      return formatedDate(text);
    }

    return text;
  }

  renderOptions() {
    const {
      options,
      name
    } = this.props;

    return (
      options.map((option) => (
        <option
          value={option.value}
          key={`${name}${option.value}`}
        >
          {this.renderOptionText(option.text)}
        </option>
      ))
    );
  }

  renderSelect() {
    const {
      selectExtraClasses,
      value,
      size
    } = this.props;

    return (
      <select
        className={selectExtraClasses}
        value={value}
        onChange={this.handleSelectChange}
        size={size}
      >
        {this.renderOptions()}
      </select>
    );
  }

  render() {
    // extraClasses can be "inline" to keep the label and select inline
    const { extraClasses } = this.props;
    return (
      <div className={`custom-select ${extraClasses}`}>
        {this.renderLabel()}
        {this.renderSelect()}
      </div>
    );
  }
}

CustomSelect.defaultProps = {
  labelText: '',
  labelInfo: '',
  optionsFormat: '',
  selectExtraClasses: '',
  value: '',
  size: 1,
  extraClasses: ''
};

CustomSelect.propTypes = {
  valueSelected: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  labelInfo: PropTypes.string,
  optionsFormat: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      text: PropTypes.string
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  selectExtraClasses: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  size: PropTypes.number,
  extraClasses: PropTypes.string
};

export default CustomSelect;
