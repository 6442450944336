const initialState = {
  showResults: false,
  fetchingResults: false,
  activityLogErrorMessage: '',
  activityLogSuccessMessage: '',
  date: 'Today',
  started_at: '',
  seconds_pedaled: 0,
  dial_position: 0,
  display_miles_cycled: 0,
  display_km_cycled: 0,
  calories_sitting: 0,
  additional_calories_pedaling: 0,
  total_calories: 0,
  total_calories_per_hour: 0,
  miles_walked: 0,
  km_walked: 0,
  steps: 0,
  speed_mph: 0,
  speed_kmph: 0,
  miles_cycled: 0,
  km_cycled: 0,
  pedal_speed_rpm: 0,
  revolutions: 0,
  pedal_strokes: 0,
  average_power: 0,
  average_torque: 0,
  average_tangential_force: 0,
  source: 'web',
  source_version: '',
  timezone: 0
};

const activityLog = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_ACTIVITY_LOG':
      return {
        ...state,
        fetchingResults: false,
        showResults: true,
        date: action.payload.date,
        ...action.payload,
        errorMessage: ''
      };
    case 'HIDE_ACTIVITY_LOG':
      return {
        ...state,
        fetchingResults: false,
        showResults: false,
        date: '',
        started_at: '',
        seconds_pedaled: 0,
        dial_position: 0,
        display_miles_cycled: 0,
        display_km_cycled: 0,
        calories_sitting: 0,
        additional_calories_pedaling: 0,
        total_calories: 0,
        total_calories_per_hour: 0,
        miles_walked: 0,
        km_walked: 0,
        steps: 0,
        speed_mph: 0,
        speed_kmph: 0,
        miles_cycled: 0,
        km_cycled: 0,
        pedal_speed_rpm: 0,
        revolutions: 0,
        pedal_strokes: 0,
        average_power: 0,
        average_torque: 0,
        average_tangential_force: 0,
        errorMessage: '',
        source_version: '',
        timezone: 0
      };
    case 'ADD_ACTIVITY_LOG_ERROR':
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    default:
      return state;
  }
};

export default activityLog;
