import { getRequest, deleteRequest } from '../utils/Requests';
import { logoutUser } from './user';
import { RollbarErrorTracking } from '../components/errors/RollbarErrorTracking';

export const fetchActivityLogs = () => ({
  type: 'FETCH_ACTIVITY_LOGS',
  payload: {
    requesting: true
  }
});

export const updateActivityLogs = (list) => ({
  type: 'UPDATE_ACTIVITY_LOGS',
  payload: {
    requesting: false,
    list
  }
});

export const addActivityLogsError = (errorMessage) => ({
  type: 'ADD_ACTIVITY_LOGS_ERROR',
  payload: {
    requesting: false,
    errorMessage
  }
});

export const requestActivityLogs = () => (dispatch) => {
  dispatch(fetchActivityLogs());

  return getRequest(
    'activity_logs'
  ).then((response) => {
    dispatch(updateActivityLogs(response.data));
  }).catch((error) => {
    if (typeof error.response !== 'undefined') {
      if (error.response.status === 401) {
        dispatch(logoutUser());
      } else {
        dispatch(addActivityLogsError(error.response.data.error));
      }
    } else {
      RollbarErrorTracking.logErrorInRollbar('requestActivityLogs::activity_logs', error.response);
    }
  });
};

export const deleteActivityLog = (id) => (dispatch, getState) => deleteRequest(
  `activity_logs/${id}`
).then(() => {
  const oldList = getState().activityLogs.list;
  const indexToRemove = oldList.map(
    (activityLog) => activityLog.id
  ).indexOf(id);

  // Remove activity log from list
  dispatch(updateActivityLogs([
    ...oldList.slice(0, indexToRemove),
    ...oldList.slice(indexToRemove + 1)
  ]));
}).catch((error) => {
  if (typeof error.response !== 'undefined') {
    if (error.response.status === 401) {
      dispatch(logoutUser());
    } else {
      dispatch(addActivityLogsError(error.response.data.error));
    }
  } else {
    RollbarErrorTracking.logErrorInRollbar('deleteActivityLog::deleteRequest', error.response);
  }
});
