const initialState = {
  messages: {
    myProgress: {
      message: '',
      status: 'ok'
    }
  },
  loading: false
};

const messaging = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_MESSAGE':
      return {
        ...state,
        loading: true
      };
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        messages: {
          ...action.payload
        },
        loading: false
      };
    case 'RESET_MESSAGE':
      return {
        ...state,
        messages: {
          ...action.payload
        },
        loading: false
      };
    default:
      return state;
  }
};

export default messaging;
