import { getRequest } from '../utils/Requests';

export const fetchMessage = () => ({
  type: 'FETCH_MESSAGE'
});

export const updateMessage = (message, status, messageType) => ({
  type: 'UPDATE_MESSAGE',
  payload: {
    [messageType]: {
      message,
      status
    }
  }
});

export const resetMessage = (messageType) => ({
  type: 'RESET_PROGRESS',
  payload: {
    [messageType]: {
      message: '',
      status: 'ok'
    }
  }
});

export const requestMessage = (user_id, options) => (dispatch) => {
  dispatch(fetchMessage());

  return getRequest('messages', {
    user_id,
    type: 'web',
    source: 'browser',
    version: '2.0',
    options
  }).then((response) => {
    const {
      message,
      status
    } = response.data;
    dispatch(updateMessage(message, status, options));
  });
};

export const deleteMessage = (messageType) => (dispatch) => {
  dispatch(resetMessage(messageType));
};
