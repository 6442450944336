import axios from 'axios';
import * as storage from './Storage';

// Set base url
const httpRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

// Set default headers
httpRequest.defaults.headers.common = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

// Add request interceptor
httpRequest.interceptors.request.use((config) => {
  const token = storage.getJWToken();

  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

export function getRequest(url, data) {
  return httpRequest.get(url, {
    params: data
  });
}

export function postRequest(url, data) {
  return httpRequest.post(url, data);
}

export function putRequest(url, data) {
  return httpRequest.put(url, data);
}

export function deleteRequest(url, data) {
  return httpRequest.delete(url, {
    params: data
  });
}

export function patchRequest(url, data) {
  return httpRequest.patch(url, data);
}
