import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomModal from '../atoms/CustomModal';
import CustomButton from '../atoms/CustomButton';

class RegisterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      username: '',
      password: '',
      passwordConfirmation: ''
    };
  }

  handleChange = (input) => (event) => {
    this.setState({
      [input]: event.target.value,
    });
  }

  handleRegisterSubmit = (event) => {
    const { onSubmitRegisterModal } = this.props;
    const {
      email,
      username,
      password,
      passwordConfirmation
    } = this.state;

    onSubmitRegisterModal(
      email,
      username,
      password,
      passwordConfirmation
    );
  }

  messageClass() {
    const {
      errorMessage
    } = this.props;

    if (errorMessage) {
      return 'error';
    }

    return 'success';
  }

  displayedMessage() {
    const {
      errorMessage,
      successMessage
    } = this.props;

    if (errorMessage) {
      return errorMessage;
    }

    return successMessage;
  }

  render() {
    const {
      show,
      onCloseModal,
    } = this.props;
    const {
      email,
      username,
      password,
      passwordConfirmation
    } = this.state;

    return (
      <div className="register-modal">
        <CustomModal
          show={show}
          onClose={onCloseModal}
          name="RegisterModal"
          title="Create New Account"
          messageClasses={this.messageClass()}
          message={this.displayedMessage()}
        >
          <div
            className="register-form"
            onSubmit={this.handleRegisterSubmit}
          >
            <label>User Name</label>
            <input
              type="text"
              name="username"
              placeholder="User Name"
              value={username}
              onChange={this.handleChange('username')}
            />
            <p className="input-info small mb-10">
              16 characters or less. Alphanumeric characters only.
            </p>
            <label>Email Address</label>
            <input
              type="text"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={this.handleChange('email')}
            />
            <label>Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={this.handleChange('password')}
            />
            <p className="input-info small mb-10">
              At least 6 characters. Include at least one uppercase letter, one lower
              case letter and a number.
            </p>
            <label>Confirm Password</label>
            <input
              type="password"
              name="password-confirmation"
              placeholder="Confirm Password"
              value={passwordConfirmation}
              onChange={this.handleChange('passwordConfirmation')}
            />
            <hr />
            <CustomButton
              extraClasses="submit large"
              type="submit"
              iconNames={['user-circle']}
              iconClasses="mr-10"
              iconColor="white"
              buttonClick={this.handleRegisterSubmit}
            >
              <span>Create Account</span>
            </CustomButton>
          </div>
        </CustomModal>
      </div>
    );
  }
}

RegisterModal.defaultProps = {
  show: false,
  errorMessage: null,
  successMessage: null
};

RegisterModal.propTypes = {
  onSubmitRegisterModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string
};

export default RegisterModal;
