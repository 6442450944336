import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as activityLogActions from '../../actions/activity_log';
import ActivityLog from '../blocks/ActivityLog';
import CalorieCalculator from '../blocks/CalorieCalculator';
import ProgressLogging from '../blocks/ProgressLogging';

function renderRequestFeatureLink() {
  return (
    <a
      className="link"
      href="https://deskcycle.com/pages/contact-support"
      target="_blank"
      rel="noopener noreferrer"
    >
      Request a Feature
    </a>
  );
}

class Calculator extends Component {
  handleCloseActiviyLog = () => {
    const { hideActivityLog } = this.props;

    hideActivityLog();
  }

  renderCalculatorSection() {
    const {
      showResults,
      date,
      seconds_pedaled,
      display_miles_cycled,
      display_km_cycled,
      calories_sitting,
      additional_calories_pedaling,
      total_calories,
      miles_walked,
      km_walked,
      steps,
      speed_mph,
      speed_kmph,
      miles_cycled,
      km_cycled,
      pedal_speed_rpm,
      revolutions,
      pedal_strokes,
      average_power,
      average_torque,
      average_tangential_force
    } = this.props;

    if (showResults) {
      return (
        <ActivityLog
          onClose={this.handleCloseActiviyLog}
          date={date}
          seconds_pedaled={seconds_pedaled}
          display_miles_cycled={display_miles_cycled}
          display_km_cycled={display_km_cycled}
          calories_sitting={calories_sitting}
          additional_calories_pedaling={additional_calories_pedaling}
          total_calories={total_calories}
          miles_walked={miles_walked}
          km_walked={km_walked}
          steps={steps}
          speed_mph={speed_mph}
          speed_kmph={speed_kmph}
          miles_cycled={miles_cycled}
          km_cycled={km_cycled}
          pedal_speed_rpm={pedal_speed_rpm}
          revolutions={revolutions}
          pedal_strokes={pedal_strokes}
          average_power={average_power}
          average_torque={average_torque}
          average_tangential_force={average_tangential_force}
        />
      );
    }

    return (
      <CalorieCalculator />
    );
  }

  render() {
    const { showResults } = this.props;

    return (
      <div className="calculator">
        {!showResults && renderRequestFeatureLink()}
        {this.renderCalculatorSection()}
        <ProgressLogging />
      </div>
    );
  }
}

Calculator.defaultProps = {
  seconds_pedaled: 0,
  display_miles_cycled: 0,
  display_km_cycled: 0,
  calories_sitting: 0,
  additional_calories_pedaling: 0,
  total_calories: 0,
  miles_walked: 0,
  km_walked: 0,
  steps: 0,
  speed_mph: 0,
  speed_kmph: 0,
  miles_cycled: 0,
  km_cycled: 0,
  pedal_speed_rpm: 0,
  revolutions: 0,
  pedal_strokes: 0,
  average_power: 0,
  average_torque: 0,
  average_tangential_force: 0
};

Calculator.propTypes = {
  showResults: PropTypes.bool.isRequired,
  hideActivityLog: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  seconds_pedaled: PropTypes.number.isRequired,
  display_miles_cycled: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  display_km_cycled: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  calories_sitting: PropTypes.number.isRequired,
  additional_calories_pedaling: PropTypes.number.isRequired,
  total_calories: PropTypes.number.isRequired,
  miles_walked: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  km_walked: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  steps: PropTypes.number.isRequired,
  speed_mph: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  speed_kmph: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  miles_cycled: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  km_cycled: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  pedal_speed_rpm: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  revolutions: PropTypes.number.isRequired,
  pedal_strokes: PropTypes.number.isRequired,
  average_power: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  average_torque: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  average_tangential_force: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

const mapStateToProps = ({ activityLog }) => (
  {
    ...activityLog
  }
);

const mapDispatchToProps = (dispatch) => (
  {
    ...bindActionCreators(activityLogActions, dispatch)
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
